import React, { useState, useContext } from 'react';
import { CollectionFill } from 'react-bootstrap-icons';
import {
  Col, Collapse, Row, Spinner,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CollapseButtonLoad from '../../../generic/CollapseButtonLoad';
import Dashboards from '../dashboard/Dashboards';
import addButton from '../../../generic/addButton';
import editButton from '../../../generic/editButton';
import postDashboard from '../../../../services/dashboards/postDashboard';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';

export default function Theme(props) {
  const {
    theme,
    projectUuid,
    companyUuid,
    setSelectedThemeUuid,
    setShowThemeEdit,
  } = props;

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectedDashboardUuid, setSelectedDashboardUuid] = useState();
  const [showDashboardEdit, setShowDashboardEdit] = useState(false);
  const { authHeader } = useContext(AuthenticationContext);
  const [themeDashboards, setThemeDashboards] = useState([]);

  function handleEditTheme() {
    setShowThemeEdit(true);
    setSelectedThemeUuid(theme.uuid);
  }

  async function handleCreateDashboard() {
    const dashboard = { name: 'New Dashboard', uuid: uuidv4() };
    const response = await postDashboard(
      authHeader,
      companyUuid,
      projectUuid,
      theme.uuid,
      dashboard,
    );
    if (response.status === 200) {
      setSelectedDashboardUuid(dashboard.uuid);
      setShowDashboardEdit(true);
      const newThemeDashboards = [...themeDashboards, dashboard];
      setThemeDashboards(newThemeDashboards);
    }
  }

  const dashboards = open ? (
    <Dashboards
      themeUuid={theme.uuid}
      projectUuid={projectUuid}
      companyUuid={companyUuid}
      loaded={loaded}
      setLoaded={setLoaded}
      selectedDashboardUuid={selectedDashboardUuid}
      setSelectedDashboardUuid={setSelectedDashboardUuid}
      showDashboardEdit={showDashboardEdit}
      setShowDashboardEdit={setShowDashboardEdit}
      dashboards={themeDashboards}
      setDashboards={setThemeDashboards}
    />
  ) : undefined;

  const loadingSpinner = open && !loaded ? (
    <Spinner
      className="ms-2"
      animation="border"
      size="sm"
      variant="primary"
    />
  ) : undefined;

  const addDashboardButton = addButton(
    'Dashboard',
    'left',
    handleCreateDashboard,
  );

  const editThemeButton = editButton(
    'Theme',
    'right',
    handleEditTheme,
  );

  return (
    <>
      <Row className="mb-2">
        <Col>
          <CollapseButtonLoad
            open={open}
            setOpen={setOpen}
            setLoaded={setLoaded}
          />
          <span className="ms-2">
            <CollectionFill className="me-1" />
            {theme.name}
          </span>
          {loadingSpinner}
        </Col>
        <Col className="text-end">
          {addDashboardButton}
          {editThemeButton}
        </Col>
      </Row>
      <Collapse in={loaded}>
        <div className="ms-4">
          {dashboards}
        </div>
      </Collapse>
    </>
  );
}
