import React from 'react';

export default function Footer() {
  return (
    <div className="text-center">
      HELD-R is een product van&nbsp;
      <a href="https://www.digital-construction.ai" target="_blank" rel="noopener noreferrer">
        DC
      </a>
      <br />
      Copyright © 2023 Digital Construction B.V. All rights reserved
    </div>
  );
}
