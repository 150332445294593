import React, {
  useCallback, useContext, useState, useEffect,
} from 'react';
import {
  Button, Col, ListGroup, Row, Form,
} from 'react-bootstrap';
import { Trash3Fill } from 'react-bootstrap-icons';
import postCompanyUser from '../../services/companyUser/postCompanyUser';
import deleteCompanyUser from '../../services/companyUser/deleteCompanyUser';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import getCompanyUsers from '../../services/companyUser/getCompanyUsers';
import Loader from '../generic/Loader';
import { AuthorizationContext } from '../auth/AuthorizationProvider';
import { AlertContext } from '../AlertProvider';

export default function CompanyUserList(props) {
  const { companyUuid } = props;
  const [users, setUsers] = useState([]);
  const [validInput, setValidInput] = useState(true);
  const [loading, setLoading] = useState(true);

  const { authHeader } = useContext(AuthenticationContext);
  const { authorization } = useContext(AuthorizationContext);
  const { addAlert } = useContext(AlertContext);

  useEffect(() => {
    async function fetchCompanyUsers() {
      const response = await getCompanyUsers(authHeader, companyUuid);
      if (response.status === 200) {
        const body = await response.json();
        setUsers(body);
      }
      setLoading(false);
    }
    fetchCompanyUsers();
  }, [authHeader, companyUuid]);
  const handleAddUser = useCallback(async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setValidInput(true);
      const userInputEl = event.target;
      const newUser = {
        email: userInputEl.value,
        auth_group: 'Member',
      };
      const response = await postCompanyUser(authHeader, companyUuid, newUser);
      if (response.status === 404) {
        setValidInput(false);
        return;
      }
      if (response.status !== 200) {
        addAlert('danger', `Failed to add user '${userInputEl.value}`);
        setValidInput(false);
        return;
      }
      const body = await response.json();
      setUsers(body);
      userInputEl.value = '';
    }
  }, [setUsers]);

  async function handleChangeAuthGroup(event, email) {
    const newUser = {
      email,
      auth_group: event.target.value,
    };
    const response = await postCompanyUser(authHeader, companyUuid, newUser);
    if (response.status !== 200) {
      addAlert('danger', `Failed to update user '${email}'`);
      return;
    }
    const body = await response.json();
    setUsers(body);
  }

  async function handleChangeDeleteUser(user) {
    const response = await deleteCompanyUser(authHeader, companyUuid, user.uuid);
    if (response.status !== 200) {
      addAlert('danger', `Failed to delete user '${user.email}'`);
      return;
    }
    const body = await response.json();
    setUsers(body);
  }

  function userElement(companyUser) {
    const { auth_group } = companyUser; // eslint-disable-line camelcase
    const { user } = companyUser;
    return (
      <ListGroup.Item key={user.uuid} variant="light">
        <Row>
          <Col className="align-self-center" xs="12" md="5">
            {user.email}
          </Col>
          <Col xs="10" md="5">
            <Form.Select
              defaultValue={auth_group} // eslint-disable-line camelcase
              onChange={(event) => handleChangeAuthGroup(event, user.email)}
              disabled={!authorization.companyUser.update}
            >
              <option value="Member">Member</option>
              <option value="Project Admin">Project Admin</option>
              <option value="Company Admin">Company Admin</option>
            </Form.Select>
          </Col>
          <Col
            className="text-end align-self-center"
            xs="2"
          >
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleChangeDeleteUser(user)}
              disabled={!authorization.companyUser.delete}
            >
              <Trash3Fill />
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  function feedbackElement() {
    if (validInput) {
      return null;
    }
    return (
      <Form.Control.Feedback type="invalid">
        User does not exist
      </Form.Control.Feedback>
    );
  }

  if (loading) {
    <Loader />;
  }

  return (
    <>
      <Form.Control
        type="email"
        placeholder="Enter new user's email"
        onKeyDownCapture={handleAddUser}
        disabled={!authorization.companyUser.create}
        isInvalid={!validInput}
      />
      {feedbackElement()}
      <ListGroup className="mt-3 mb-3">
        {users.map((user) => userElement(user))}
      </ListGroup>
    </>
  );
}
