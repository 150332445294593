import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ThemeItem from './ThemeItem';

export default function ThemeItems(params) {
  const {
    themes,
    projectUuid,
    companyUuid,
    setDashboard,
  } = params;
  if (themes.length === 0) {
    return (
      <Col md={12}>
        <Row>
          <div style={{ width: 20 }} />
          <Col>
            <span className="text-muted">
              No themes
            </span>
          </Col>
        </Row>
      </Col>
    );
  }
  return (
    <>
      {themes.map((theme) => (
        <ThemeItem
          key={theme.uuid}
          theme={theme}
          projectUuid={projectUuid}
          companyUuid={companyUuid}
          setDashboard={setDashboard}
        />
      ))}
    </>
  );
}
