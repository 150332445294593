export default async function getDashboardEmbed(
  authHeader,
  companyUuid,
  projectUuid,
  themeUuid,
  dashboardUuid,
) {
  return fetch(
    `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/themes/${themeUuid}/dashboards/${dashboardUuid}/embed`,
    { headers: authHeader },
  );
}
