import React, {
  useCallback, useContext, useState, useEffect,
} from 'react';
import {
  Alert,
  Button, Col, ListGroup, Row,
} from 'react-bootstrap';
import { PencilSquare, Trash3Fill } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import postServicePrincipal from '../../services/companyServicePrincipal/postCopmanyServicePrincipal';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import CompanyServicePrincipalEditModal from './CompanyServicePrincipalEditModal';
import deleteServicePrincipal from '../../services/companyServicePrincipal/deleteCompanyServicePrincipal';
import Loader from '../generic/Loader';
import getCompanyServicePrincipals from '../../services/companyServicePrincipal/getCompanyServicePrincipals';
import { AuthorizationContext } from '../auth/AuthorizationProvider';

export default function ServicePrincipalList(props) {
  const {
    companyUuid,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { authorization } = useContext(AuthorizationContext);

  const [selectedServicePrincipalId, setSelectedServicePrincipalId] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [servicePrincipals, setCompanyServicePrincipal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertHeader, setAlertHeader] = useState('');

  useEffect(() => {
    async function fetchServicePrincipals() {
      const response = await getCompanyServicePrincipals(authHeader, companyUuid);
      if (response.status === 200) {
        const body = await response.json();
        setCompanyServicePrincipal(body);
      }
      setLoading(false);
    }
    fetchServicePrincipals();
  }, [authHeader, companyUuid]);
  const handleAddServicePrincipal = useCallback(() => {
    const newServicePrincipal = {
      uuid: uuidv4(),
      name: 'New Service Principle',
      client_id: '',
      client_secret: '',
      tenant_id: '',
    };
    postServicePrincipal(
      authHeader,
      companyUuid,
      newServicePrincipal,
    );
    setCompanyServicePrincipal([...servicePrincipals, newServicePrincipal]);
    setSelectedServicePrincipalId(newServicePrincipal.uuid);
    setShowEditModal(true);
  }, [
    servicePrincipals,
    setCompanyServicePrincipal,
    setSelectedServicePrincipalId,
    setShowEditModal,
  ]);

  const handleServicePrincipalChange = useCallback((uuid, servicePrincipal) => {
    const newServicePrincipals = [...servicePrincipals];
    const index = newServicePrincipals.findIndex((sP) => sP.uuid === uuid);
    newServicePrincipals[index] = servicePrincipal;
    setCompanyServicePrincipal(newServicePrincipals);
  }, [servicePrincipals, setCompanyServicePrincipal]);

  function hanleEditServicePrincipal(servicePrincipalUuid) {
    setSelectedServicePrincipalId(servicePrincipalUuid);
    setShowEditModal(true);
  }

  async function handleDeleteServicePrincipal(servicePrincipalUuid) {
    const response = await deleteServicePrincipal(authHeader, companyUuid, servicePrincipalUuid);
    if (response.status === 200) {
      const newServicePrincipals = servicePrincipals.filter(
        (sP) => sP.uuid !== servicePrincipalUuid,
      );
      setCompanyServicePrincipal(newServicePrincipals);
    } else {
      setAlertHeader('Can not delete service principal.');
      const body = await response.json();
      setAlertMsg(body.detail);
      setShowAlert(true);
    }
  }
  function servicePrincipalElement(servicePrincipal) {
    return (
      <ListGroup.Item key={servicePrincipal.uuid} variant="light">
        <Row>
          <Col className="align-self-center">
            {servicePrincipal.name}
          </Col>
          <Col className="text-end">
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDeleteServicePrincipal(servicePrincipal.uuid)}
              className="me-1"
              disabled={!authorization.companyServicePrincipal.delete}
            >
              <Trash3Fill />
            </Button>
            <Button
              size="sm"
              onClick={() => hanleEditServicePrincipal(servicePrincipal.uuid)}
              disabled={!authorization.companyServicePrincipal.update}

            >
              <PencilSquare />
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Alert show={showAlert} variant="danger">
        <Alert.Heading>{alertHeader}</Alert.Heading>
        <p>
          {alertMsg}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShowAlert(false)} variant="outline-danger">
            Close
          </Button>
        </div>
      </Alert>
      <Row className="mb-3">
        <Col>
          <ListGroup>
            {servicePrincipals.map((sp) => servicePrincipalElement(sp))}
          </ListGroup>
          {selectedServicePrincipalId ? (
            <CompanyServicePrincipalEditModal
              showState={showEditModal}
              setShowState={setShowEditModal}
              selectedServicePrincipalUuid={selectedServicePrincipalId}
              handleServicePrincipalChange={handleServicePrincipalChange}
              companyUuid={companyUuid}
            />
          ) : undefined}
        </Col>
      </Row>
      <Row>
        <Col className="text-end me-3">
          <Button
            onClick={handleAddServicePrincipal}
            disabled={!authorization.companyServicePrincipal.create}
          >
            Add Service Principal
          </Button>
        </Col>
      </Row>

    </>
  );
}
