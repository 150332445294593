import React, { useCallback, useContext, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import getThemes from '../../../../services/themes/getThemes';
import Theme from './Theme';
import ThemeEdit from './ThemeEdit';

function Themes(props) {
  const {
    selectedThemeUuid,
    companyUuid,
    projectUuid,
    loaded,
    setLoaded,
    setSelectedThemeUuid,
    themes,
    setThemes,
    showThemeEdit,
    setShowThemeEdit,
  } = props;

  const { authHeader } = useContext(AuthenticationContext);
  useEffect(() => {
    async function fetchThemes() {
      const response = await getThemes(authHeader, companyUuid, projectUuid);
      if (response.status === 200) {
        const body = await response.json();
        setThemes(body);
      }
      setLoaded(true);
    }
    fetchThemes();
  }, [authHeader, companyUuid, projectUuid]);

  const setThemeName = useCallback((uuid, themeName) => {
    const newThemes = [...themes];
    const index = newThemes.findIndex((t) => t.uuid === uuid);
    newThemes[index].name = themeName;
    setThemes(newThemes);
  }, [themes, setThemes]);

  const setDeleteTheme = useCallback((uuid) => {
    const newThemes = themes.filter((t) => t.uuid !== uuid);
    setThemes(newThemes);
  }, [themes, setThemes]);

  if (!loaded) {
    return undefined;
  }
  return (
    <>
      {themes.length ? themes.map((theme) => (
        <Theme
          key={theme.uuid}
          theme={theme}
          projectUuid={projectUuid}
          companyUuid={companyUuid}
          setSelectedThemeUuid={setSelectedThemeUuid}
          setThemeName={setThemeName}
          setDeleteTheme={setDeleteTheme}
          setShowThemeEdit={setShowThemeEdit}
        />
      )) : (
        <Row className="mb-2">
          <Col>
            <span className="fst-italic">No themes</span>
          </Col>
        </Row>
      )}
      {selectedThemeUuid ? (
        <ThemeEdit
          themeUuid={selectedThemeUuid}
          projectUuid={projectUuid}
          companyUuid={companyUuid}
          showThemeEdit={showThemeEdit}
          setShowThemeEdit={setShowThemeEdit}
          setThemeName={setThemeName}
          setDeleteTheme={setDeleteTheme}
        />
      ) : undefined}
    </>
  );
}

export default Themes;
