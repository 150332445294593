export default async function postContact(
  authHeader,
  firstName,
  lastName,
  email,
  phoneNumber,
  subject,
  message,
  privacyChecked,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      subject,
      message,
      accept_terms: privacyChecked,
    }),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/contact`;
  return fetch(url, options);
}
