import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import resetPassword from '../../services/authentication/resetPassword';

export default function ResetPassword() {
  const [email, setEmail] = useState();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailMsg, setShowFailMsg] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const response = await resetPassword(
        email,
      );
      if (response.status === 200) {
        setShowSuccessMsg(true);
      } else {
        setShowFailMsg(true);
      }
    }
  };

  const resetPasswordForm = (
    <>
      <p>
        If you have forgotten your password, enter your email address below
        and we will send you a link to reset your password.
      </p>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div
          className="mb-2"
        >
          <div className="form-floating">
            <Form.Control
              id="emailInput"
              className="solo"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Label htmlFor="emailInput">
              Email
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </div>
        </div>
        <Button size="lg" type="submit">
          Reset Password
        </Button>
      </Form>
    </>
  );

  const successMsg = (
    <div>
      <p>
        A link to reset your password has been sent to your email address.
      </p>
      <p>
        If you do not receive an email, please contact support.
      </p>
    </div>
  );

  const failMsg = (
    <div>
      <p>
        Something went wrong.
      </p>
      <p>
        Please contact support.
      </p>
    </div>
  );

  if (showSuccessMsg) {
    return successMsg;
  }

  if (showFailMsg) {
    return failMsg;
  }

  if (!showSuccessMsg && !showFailMsg) {
    return resetPasswordForm;
  }
  if (showSuccessMsg) {
    return successMsg;
  }
  return failMsg;
}
