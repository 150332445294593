import React from 'react';
import { Image } from 'react-bootstrap';
import {
  Outlet, Link,
} from 'react-router-dom';
import logoImg from '../../media/held-r-logo.png';
import '../../css/authContainer.css';
import routes from '../routes';

export default function AuthLayout() {
  return (
    <div className="auth-container text-center position-absolute top-50 start-50 translate-middle">
      <Link to={routes.home}>
        <Image className="mb-4" src={logoImg} alt="" height="72" />
      </Link>
      <Outlet />
    </div>
  );
}
