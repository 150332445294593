export default async function getProjectServicePrincipals(authHeader, companUuid, projectUuid) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'get',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/projects/${projectUuid}/service_principals`;
  return fetch(url, options);
}
