import React, {
  useEffect, useContext, useState, useCallback,
} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import useDebounce from '../../../../customHooks/useDebounce';
import putDashboard from '../../../../services/dashboards/putDashboard';
import deleteDashboard from '../../../../services/dashboards/deleteDashboard';
import getDashboard from '../../../../services/dashboards/getDashboard';
import getProjectServicePrincipals from '../../../../services/projects/servicePrincipal/getProjectServicePrincipals';
import SelectWorkspace from './SelectWorkspace';
import SelectReport from './SelectReport';
import Loader from '../../../generic/Loader';
import { AlertContext } from '../../../AlertProvider';

export default function DashboardEdit(props) {
  const {
    dashboardUuid,
    setDashboardUuid,
    themeUuid,
    projectUuid,
    companyUuid,
    showDashboardEdit,
    setShowDashboardEdit,
    setDashboardName,
    setDeleteDashboard,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  const [showConfirmationModal, setShowConfirmationModel] = useState(false);
  const [updatedDashboard, setUpdatedDashboard] = useState();
  const [dashboard, setDashboard] = useState();
  const [projectServicePrincipals, setProjectServicePrincipals] = useState();
  const [loadingBody, setLoadingBody] = useState(true);

  useEffect(() => {
    setLoadingBody(true);
  }, [dashboardUuid]);

  useEffect(() => {
    async function fetchDashboard() {
      const response = await getDashboard(
        authHeader,
        companyUuid,
        projectUuid,
        themeUuid,
        dashboardUuid,
      );
      if (response.status === 200) {
        const body = await response.json();
        setDashboard(body);
      }
      setTimeout(() => {
        setLoadingBody(false);
      }, 250);
    }
    fetchDashboard();
  }, [authHeader,
    companyUuid,
    projectUuid,
    themeUuid,
    dashboardUuid,
  ]);

  useEffect(() => {
    async function fetchServicePrincipals() {
      const response = await getProjectServicePrincipals(
        authHeader,
        companyUuid,
        projectUuid,
      );
      if (response.status === 200) {
        const body = await response.json();
        setProjectServicePrincipals(body);
      }
    }
    fetchServicePrincipals();
  }, [
    dashboardUuid,
    authHeader,
    companyUuid,
    projectUuid,
  ]);

  useDebounce(
    () => putDashboard(authHeader, companyUuid, projectUuid, themeUuid, dashboard),
    1000,
    [updatedDashboard],
  );

  const handleClose = useCallback(() => {
    setShowDashboardEdit(false);
    setDashboardUuid(null);
  }, [
    setShowDashboardEdit,
    setDashboardUuid,
  ]);

  const handleSave = useCallback(async () => {
    const response = await putDashboard(authHeader, companyUuid, projectUuid, themeUuid, dashboard);
    if (response.status !== 200) {
      addAlert('danger', `Failed to save dashboard '${dashboard.name}'`);
      return;
    }
    setDashboardName(dashboard.uuid, dashboard.name);
    handleClose();
  }, [
    authHeader,
    companyUuid,
    projectUuid,
    themeUuid,
    dashboard,
    handleClose,
    addAlert,
    setDashboardName,
  ]);

  const handlePreDelete = useCallback(() => {
    setShowDashboardEdit(false);
    setShowConfirmationModel(true);
  }, [
    setShowDashboardEdit,
    setShowConfirmationModel,
  ]);

  const handleConfirmDelete = useCallback(() => {
    deleteDashboard(authHeader, companyUuid, projectUuid, themeUuid, dashboard.uuid);
    setDeleteDashboard(dashboard.uuid);
    setShowConfirmationModel(false);
  }, [
    dashboard,
    deleteDashboard,
    setDeleteDashboard,
    setShowConfirmationModel,
  ]);

  const handleCancelDelete = useCallback(() => {
    setShowConfirmationModel(false);
    setShowDashboardEdit(true);
  }, [
    setShowDashboardEdit,
    setShowConfirmationModel,
  ]);

  const handleChange = useCallback((changes) => {
    const newUpdatedDashboard = { ...dashboard, ...changes };
    setDashboard(newUpdatedDashboard);
    setUpdatedDashboard(newUpdatedDashboard);
  }, [
    dashboard,
    setDashboard,
    setUpdatedDashboard,
  ]);

  const handleServicePrincipalChange = useCallback((e) => {
    const servicePrincipalUuid = e.target.value !== '0' ? e.target.value : null;
    handleChange(
      {
        service_principal_uuid: servicePrincipalUuid,
        group_id: null,
        report_id: null,
      },
    );
  }, [
    handleChange,
  ]);

  function getServicePrincipalOptions() {
    if (projectServicePrincipals.length !== 0) {
      return (
        <Form.Select
          onChange={(e) => handleServicePrincipalChange(e)}
          value={dashboard.service_principal_uuid ? dashboard.service_principal_uuid : '0'}
        >
          <option value="0">-</option>
          {projectServicePrincipals.map((servicePrincipal) => (
            <option key={servicePrincipal.uuid} value={servicePrincipal.uuid}>
              {servicePrincipal.name}
            </option>
          ))}
        </Form.Select>
      );
    }
    return (
      <Form.Select disabled>
        <option>
          No Service Principals assigned to this project.
        </option>
      </Form.Select>
    );
  }

  const modelContent = loadingBody ? <Loader /> : (
    <>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Dashboard name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Dashboard name"
            value={dashboard.name}
            onInput={(e) => handleChange({ name: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Service Principal</Form.Label>
          {getServicePrincipalOptions()}
        </Form.Group>
        {dashboard.service_principal_uuid ? (
          <SelectWorkspace
            handleChangeDashboard={handleChange}
            dashboardWorkspaceUuid={dashboard.group_id}
            dashboardServicePrincipalUuid={dashboard.service_principal_uuid}
            projectUuid={projectUuid}
            companyUuid={companyUuid}
          />
        ) : undefined}
        {dashboard.service_principal_uuid && dashboard.group_id
          ? (
            <SelectReport
              handleChangeDashboard={handleChange}
              dashboardReportUuid={dashboard.report_id}
              dashboardWorkspaceUuid={dashboard.group_id}
              dashboardServicePrincipalUuid={dashboard.service_principal_uuid}
              projectUuid={projectUuid}
              companyUuid={companyUuid}
            />
          ) : undefined}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="danger" onClick={handlePreDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </>
  );

  return (
    <>
      <Modal show={showDashboardEdit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit dashboard
          </Modal.Title>
        </Modal.Header>
        {modelContent}
      </Modal>
      {!dashboard ? undefined : (
        <Modal show={showConfirmationModal} onHide={handleCancelDelete}>
          <Modal.Body>
            Are you sure you want to delete dashboard &apos;
            {dashboard.name}
            &apos;?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCancelDelete}>
              No
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
