export default async function putCompanyServicePrincipal(
  authHeader,
  companUuid,
  servicePrincipalUuid,
  servicePrincipal,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'PUT',
    body: JSON.stringify(servicePrincipal),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/service_principals/${servicePrincipalUuid}`;
  const response = await fetch(url, options);
  return response;
}
