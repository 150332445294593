export default async function putTheme(authHeader, companyUuid, projectUuid, theme) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'PUT',
    body: JSON.stringify(theme),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/themes/${theme.uuid}`;
  return fetch(url, options);
}
