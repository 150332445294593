export default async function deleteProject(authHeader, companyUuid, project) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'DELETE',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${project.uuid}`;
  const response = await fetch(url, options);
  let json;
  if (response.status === 200) {
    json = await (response.json());
  } else {
    json = [];
  }
  return json;
}
