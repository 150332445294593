import React, { useState, useContext } from 'react';
import {
  Nav, Collapse, Col, Row,
} from 'react-bootstrap';
import DashboardItems from './DashboardItems';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import { AlertContext } from '../../AlertProvider';
import getTheme from '../../../services/themes/getTheme';
import CollapseButtonLoad from '../../generic/CollapseButtonLoad';
import SideBarSpinner from './SideBarSpinner';

export default function ThemeItem(params) {
  const {
    theme,
    projectUuid,
    companyUuid,
    setDashboard,
  } = params;

  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [dashboards, setDashboards] = useState([]);

  async function fetchTheme() {
    const response = await getTheme(
      authHeader,
      companyUuid,
      projectUuid,
      theme.uuid,
    );
    const body = await response.json();
    if (response.status !== 200) {
      addAlert('danger', 'Could not fetch theme');
      return;
    }
    setDashboards(body.dashboards);
    setLoaded(true);
  }

  return (
    <Nav.Item>
      <Nav.Link onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <Row>
          <div style={{ width: 20 }}>
            <CollapseButtonLoad
              open={open}
              setOpen={setOpen}
              setLoaded={setLoaded}
            />
          </div>
          <Col>
            {theme.name}
          </Col>
        </Row>
      </Nav.Link>
      <Collapse in={open} onEnter={() => fetchTheme()}>
        <div className="ms-3">
          {loaded ? (
            <DashboardItems
              dashboards={dashboards}
              companyUuid={companyUuid}
              projectUuid={projectUuid}
              themeUuid={theme.uuid}
              setDashboard={setDashboard}

            />
          ) : <SideBarSpinner />}
        </div>
      </Collapse>
    </Nav.Item>
  );
}
