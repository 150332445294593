import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BarChartFill } from 'react-bootstrap-icons';
import editButton from '../../../generic/editButton';
import routes from '../../../routes';

export default function Dashboard(props) {
  const {
    dashboard,
    themeUuid,
    projectUuid,
    companyUuid,
    setSelectedDashboardUuid,
    setShowDashboardEdit,
  } = props;

  function handleEditDashboard() {
    setShowDashboardEdit(true);
    setSelectedDashboardUuid(dashboard.uuid);
  }

  const editDashboard = editButton(
    'Dashboard',
    'right',
    handleEditDashboard,
  );
  const path = (
    routes.app.dashboard.replace(':dashboardUuid', dashboard.uuid).replace(':themeUuid', themeUuid).replace(':projectUuid', projectUuid).replace(':companyUuid', companyUuid)
  );
  return (
    <Row className="mb-2">
      <Col>
        <Link to={path}>
          <BarChartFill className="me-1" />
          {dashboard.name}
        </Link>
      </Col>
      <Col className="text-end">
        {editDashboard}
      </Col>
    </Row>
  );
}
