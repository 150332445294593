export default async function getThemes(authHeader, companyUuid, projectUuid) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'GET',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/themes`;
  return fetch(url, options);
}
