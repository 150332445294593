import React from 'react';
import { Col, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';

export default function ProfileNavItems(params) {
  const { companies } = params;

  function companyLink(company) {
    const path = routes.app.company.replace(':companyUuid', company.uuid);
    return (
      <NavLink key={company.uuid} to={path} className="nav-link">{company.name}</NavLink>
    );
  }

  function companyLinks() {
    if (companies.length === 0) {
      return undefined;
    }
    return (
      <>
        {companies.map((company) => (
          companyLink(company)
        ))}
        <NavDropdown.Divider />
      </>
    );
  }
  return (
    <Col>
      <Nav className="flex-column w-100 ms-2">
        <Nav.Link as={NavLink} to={routes.app.profile}>Profile</Nav.Link>
        <NavDropdown.Divider />
        {companyLinks()}
        <Nav.Link as={NavLink} to={routes.auth.logout}>Logout</Nav.Link>
        <Nav.Link as={NavLink} to={routes.contact}>Contact</Nav.Link>
      </Nav>
    </Col>
  );
}
