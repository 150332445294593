import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import React from 'react';

export default function toolTip(
  toolTipText,
  placement,
  buttonElement,
) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={(
        <Tooltip id="button-tooltip">
          {toolTipText}
        </Tooltip>
      )}
    >
      {buttonElement}
    </OverlayTrigger>
  );
}
