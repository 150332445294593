import React, { useMemo, useState } from 'react';

export const SideBarContext = React.createContext();

export default function SideBarContentProvider({ children }) {
  const [sidebarContent, setSidebarContent] = useState();

  const SideBarContextValue = useMemo(() => ({
    sidebarContent,
    setSidebarContent,
  }));

  return (
    <SideBarContext.Provider value={SideBarContextValue}>
      {children}
    </SideBarContext.Provider>
  );
}
