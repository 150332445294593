import React, { useState, useContext } from 'react';
import {
  Container, Form, Button, Row, Col, Fade,
} from 'react-bootstrap';
import postContact from '../../services/contact/postContact';
import { AuthenticationContext } from '../auth/AuthenticationProvider';

export default function Contact() {
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showContactForm, setShowContactForm] = useState(true);
  const [msgSentSuccessfully, setMsgSentSuccessfully] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const { authHeader } = useContext(AuthenticationContext);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await postContact(
      authHeader,
      firstName,
      lastName,
      email,
      phoneNumber,
      subject,
      message,
      privacyChecked,
    );
    setShowContactForm(false);
    if (response.status !== 200) {
      setMsgSentSuccessfully(false);
    }
    setMsgSentSuccessfully(true);
  };

  const handleShowMsg = () => {
    if (msgSentSuccessfully) {
      setShowSuccessMsg(true);
    } else {
      setShowErrorMsg(true);
    }
  };

  const handleExitMsg = () => {
    setShowSuccessMsg(false);
    setShowErrorMsg(false);
  };

  const handleShowContactForm = () => {
    setShowContactForm(true);
  };

  const returnToMsgBtn = (
    <Button variant="primary" onClick={handleExitMsg}>
      Terug naar bericht
    </Button>
  );

  return (
    <Container>
      <Row className="my-5 ">
        <Col md={6}>
          <div className="p-5">
            <h1>Contact</h1>
            <p>
              Heb je interesse in HELD-R of wil je meer informatie? Vul dan het formulier in of
              stuur een e-mail naar&nbsp;
              <a href="mailto:info@held-r.com">info@held-r.com</a>
              .
            </p>
            <p>
              HELD-R is een product van&nbsp;
              <a href="https://www.digital-construction.ai" target="_blank" rel="noopener noreferrer">
                DC
              </a>
              .
              <br />
              Vijverhofstraat 47 - unit 400
              <br />
              3032 SB - Rotterdam
            </p>
            <p>
              Volg ons op&nbsp;
              <a href="https://www.linkedin.com/company/dc-data-driven-construction/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
              .
            </p>
          </div>
        </Col>
        <Col
          md={6}
          className="bg-white shadow rounded"
        >
          <div className="m-5">
            <Fade
              in={showContactForm}
              unmountOnExit
              mountOnEnter
              onExited={handleShowMsg}
            >
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <h2>Wat kunnen we verhelderen?</h2>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3 required">
                      <Form.Label>Naam</Form.Label>
                      <Form.Control
                        ype="text"
                        required
                        placeholder="Naam"
                        value={firstName}
                        onInput={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Achternaam</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Achternaam"
                        value={lastName}
                        onInput={(e) => setLastName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>E-mailadres</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        placeholder="E-mailadres"
                        value={email}
                        onInput={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Telefoonnummer</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Telefoonnummer"
                        value={phoneNumber}
                        onInput={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Onderwerp</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Onderwerp"
                        value={subject}
                        onInput={(e) => setSubject(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Bericht</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        required
                        placeholder="Bericht"
                        value={message}
                        onInput={(e) => setMessage(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        onChange={() => setPrivacyChecked(!privacyChecked)}
                        checked={privacyChecked}
                        label={(
                          <span>
                            Ik ga akkoord met de
                            &nbsp;
                            <a href="https://digital-construction.ai/privacy-verklaring" target="_blank" rel="noopener noreferrer">
                              privacyverklaring
                            </a>
                          </span>
                        )}
                        required
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={!privacyChecked} className="px-5">
                      Verstuur
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Fade>
            <Fade
              in={showSuccessMsg}
              unmountOnExit
              mountOnEnter
              onExited={handleShowContactForm}
            >
              <div>
                <h2>Bedankt voor je bericht!</h2>
                <p>
                  We nemen zo snel mogelijk contact met je op.
                </p>
                {returnToMsgBtn}
              </div>
            </Fade>
            <Fade
              in={showErrorMsg}
              unmountOnExit
              mountOnEnter
              onEnter={handleShowContactForm}
            >
              <div>
                <h2>Oeps, dat ging mis!</h2>
                <p>
                  Probeer het later nog eens of stuur rechtstreeks een e-mail naar&nbsp;
                  <a href="mailto: info@held-r.com">info@held-r.com</a>
                  .
                </p>
                {returnToMsgBtn}
              </div>
            </Fade>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
