import React, { useContext } from 'react';
import {
  Link, NavLink,
} from 'react-router-dom';
import {
  Navbar, Image, Nav, Button, Container,
} from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import logoImg from '../../media/held-r-logo.png';
import routes from '../routes';

export default function NavBar() {
  const { token } = useContext(AuthenticationContext);
  const btnMargin = 'm-1';

  let userNav;
  if (token) {
    userNav = (
      <Button variant="primary" as={NavLink} to={routes.app.index} className={btnMargin}>Go to app</Button>
    );
  } else {
    userNav = (
      <Button variant="primary" as={NavLink} to={routes.auth.login} className={btnMargin}>Login</Button>
    );
  }

  return (
    <div className="shadow-sm">
      <Navbar expand="lg" className="mx-3 bg-white">
        <Container>
          <Navbar.Brand>
            <Link to={routes.home}>
              <Image src={logoImg} className="logo-header" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbar"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <Navbar.Brand as={NavLink} to="/"><Image src={logoImg} className="logo-header" /></Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex align-items-center">
              <Nav className="justify-content-end flex-grow-1">
                <Button variant="outline-dark" as={NavLink} to={routes.contact} className={btnMargin}>Contact</Button>
                {userNav}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
