import React, { useMemo } from 'react';
import UseToken from './UseToken';

export const AuthenticationContext = React.createContext();

export default function AuthenticationProvider({ children }) {
  const { token, setToken } = UseToken();
  const authHeader = { Authorization: `Bearer ${token}` };
  const AuthenticationContextValue = useMemo(() => ({
    token,
    setToken,
    authHeader,
  }), [token, setToken, authHeader]);

  return (
    <AuthenticationContext.Provider value={AuthenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
}
