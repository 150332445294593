import React from 'react';

export default function FactCard(params) {
  const { icon, title, text } = params;

  return (
    <div className="text-center p-3">
      {icon}
      <h3>
        {title}
      </h3>
      <p>
        {text}
      </p>
    </div>
  );
}
