import React, { useContext, useState, useEffect } from 'react';
import { Col, Nav } from 'react-bootstrap';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import getMyProjects from '../../../services/projects/getMyProjects';
import { AlertContext } from '../../AlertProvider';
import Loader from '../../generic/Loader';
import ProjectItems from './ProjectItems';

export default function DashboardViewerSideBar({ setDashboard }) {
  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchMyProjects() {
      const response = await getMyProjects(authHeader);
      setLoading(false);
      if (response.status !== 200) {
        addAlert('danger', 'Could not fetch projects');
        return;
      }
      const body = await response.json();
      setProjects(body);
    }
    fetchMyProjects();
  }, [authHeader]);

  if (loading) {
    return <Loader />;
  }
  return (
    <Col>
      <Nav>
        <ProjectItems
          projects={projects}
          setDashboard={setDashboard}
        />
      </Nav>
    </Col>
  );
}
