import Alert from 'react-bootstrap/Alert';
import React, { useMemo, useState } from 'react';
import '../css/alert.css';

export const AlertContext = React.createContext();

export default function AlertProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (variant, message) => {
    const key = Math.random().toString(36);
    setAlerts([...alerts, { key, variant, message }]);
  };

  const removeAlert = (index) => {
    const updatedAlerts = [...alerts];
    updatedAlerts.splice(index, 1);
    setAlerts(updatedAlerts);
  };

  const AlertContextValue = useMemo(() => ({
    addAlert,
  }));

  return (
    <AlertContext.Provider value={AlertContextValue}>
      {children}
      <div className="alerts-container">
        {alerts.map((alert, index) => (
          <Alert
            key={alert.key}
            variant={alert.variant}
            onClose={() => removeAlert(index)}
            dismissible
          >
            {alert.message}
          </Alert>
        ))}
      </div>
    </AlertContext.Provider>
  );
}
