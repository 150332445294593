export default async function deleteProjectServicePrincipal(
  authHeader,
  companUuid,
  projectUuid,
  servicePrincipalUuid,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'DELETE',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/projects/${projectUuid}/service_principals/${servicePrincipalUuid}`;
  const response = await fetch(url, options);
  return response;
}
