import React from 'react';
import AppRoutes from './AppRoutes';
import AuthorizationProvider from './auth/AuthenticationProvider';
import AlertProvider from './AlertProvider';
import '../css/general.css';

function App() {
  return (
    <AuthorizationProvider>
      <AlertProvider>
        <AppRoutes />
      </AlertProvider>
    </AuthorizationProvider>
  );
}

export default App;
