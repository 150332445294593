import React, {
  useEffect, useContext, useState,
} from 'react';
import { useParams, NavLink } from 'react-router-dom';
import {
  Col,
  Container, Nav, NavItem, Row,
} from 'react-bootstrap';
import getCompany from '../../services/companies/getCompany';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import AuthorizationProvider from '../auth/AuthorizationProvider';
import getCompanyEditAuth from '../../services/authorization/getCompanyEditAuth';
import CompanyEditTabs from './CompanyEditTabs';
import { SideBarContext } from '../layouts/SideBarContentProvider';
import routes from '../routes';

export default function CompanyEdit() {
  const { companyUuid } = useParams();
  const { authHeader } = useContext(AuthenticationContext);
  const [company, setCompany] = useState();
  const { setSidebarContent } = useContext(SideBarContext);

  useEffect(() => {
    setSidebarContent((
      <Col>
        <Nav className="flex-column w-100 ms-2">
          <NavItem>
            <NavLink
              to={routes.app.index}
              className={({ isActive }) => [isActive ? 'nav-link' : 'nav-link'].join(' ')}
            >
              My Dashboards
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
    ));
  }, [setSidebarContent]);

  useEffect(() => {
    async function fetchCompany() {
      const response = await getCompany(authHeader, companyUuid);
      if (response.status) {
        const body = await response.json();
        setCompany(body);
      }
    }
    fetchCompany();
  }, [authHeader, companyUuid]);

  if (company) {
    return (
      <Container>
        <Row className="mb-3">
          <h1>
            {company.name}
          </h1>
        </Row>
        <Row>
          <AuthorizationProvider
            authorizationFunction={getCompanyEditAuth}
            authorizationFunctionArgs={{ companyUuid }}
          >
            <CompanyEditTabs
              company={company}
            />
          </AuthorizationProvider>
        </Row>
      </Container>
    );
  }
}
