export default async function changePassword(userUuid, token, password) {
  const resetBody = {
    password,
  };
  const headers = { 'Content-Type': 'application/json' };

  return fetch(`${process.env.REACT_APP_HELDR_API_URL}/password_reset/${userUuid}/${token}`, {
    method: 'POST',
    body: JSON.stringify(resetBody),
    headers,
  });
}
