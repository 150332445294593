import React, {
  useEffect, useContext, useState, useCallback,
} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, ListGroup } from 'react-bootstrap';

import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import putProject from '../../../services/projects/putProject';
import deleteProject from '../../../services/projects/deleteProject';
import getProject from '../../../services/projects/getProject';
import ProjectUsers from './ProjectUsers';
import EditProjectServicePrincipals from './ProjectServicePrincipals';
import Loader from '../../generic/Loader';
import getCompanyServicePrincipals from '../../../services/companyServicePrincipal/getCompanyServicePrincipals';
import getCompanyUsers from '../../../services/companyUser/getCompanyUsers';
import { AuthorizationContext } from '../../auth/AuthorizationProvider';
import { AlertContext } from '../../AlertProvider';

export default function EditProject(props) {
  const {
    handleProjectsChange,
    handleProjectDelete,
    companyUuid,
    selectedProjectUuid,
    setShowProjectEdit,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { authorization } = useContext(AuthorizationContext);
  const { addAlert } = useContext(AlertContext);

  const [showConfirmationModal, setShowConfirmationModel] = useState(false);
  const [showProjectEditModal, setShowProjectEditModal] = useState(true);
  const [project, setProject] = useState();
  const [companyServicePrincipals, setCompanyServicePrincipals] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingCompanyServicePrincipals, setLoadingCompanyServicePrincipals] = useState(true);
  const [loadingCompanyUsers, setLoadingCompanyUsers] = useState(true);

  const loading = Boolean(loadingProject && loadingCompanyServicePrincipals && loadingCompanyUsers);

  // TODO: create one request to get all the data
  useEffect(() => {
    async function fetchProject() {
      const response = await getProject(authHeader, companyUuid, selectedProjectUuid);
      if (response.status !== 200) {
        addAlert('danger', `Failed to fetch project '${selectedProjectUuid}'`);
        return;
      }
      const body = await response.json();
      setProject(body);
      setLoadingProject(false);
    }
    if (showProjectEditModal) {
      fetchProject();
    }
  }, [authHeader, companyUuid, selectedProjectUuid, showProjectEditModal]);

  useEffect(() => {
    async function fetchCompanyServicePrincipals() {
      const response = await getCompanyServicePrincipals(authHeader, companyUuid);
      if (response.status === 200) {
        const body = await response.json();
        setCompanyServicePrincipals(body);
      }
      setLoadingCompanyServicePrincipals(false);
    }
    if (showProjectEditModal) {
      fetchCompanyServicePrincipals();
    }
  }, [authHeader, companyUuid, showProjectEditModal]);

  useEffect(() => {
    async function fetchCompanyUsers() {
      const response = await getCompanyUsers(authHeader, companyUuid);
      if (response.status !== 200) {
        addAlert('danger', `Failed to fetch users for company '${companyUuid}'`);
        return;
      }
      const body = await response.json();
      setCompanyUsers(body);
      setLoadingCompanyUsers(false);
    }
    if (showProjectEditModal) {
      fetchCompanyUsers();
    }
  }, [authHeader, companyUuid, selectedProjectUuid, showProjectEditModal]);

  const handleClose = useCallback(() => {
    setShowProjectEditModal(false);
    setShowProjectEdit(false);
  }, [setShowProjectEditModal]);

  const handlePreDelete = useCallback(() => {
    setShowConfirmationModel(true);
    setShowProjectEditModal(false);
  }, [setShowProjectEditModal, setShowConfirmationModel]);

  const handleConfirmDelete = useCallback(() => {
    deleteProject(authHeader, companyUuid, project);
    handleProjectDelete(project.uuid);
    setShowConfirmationModel(false);
    handleClose();
  }, [project, handleProjectDelete, setShowConfirmationModel]);

  const handleCancelDelete = useCallback(() => {
    setShowConfirmationModel(false);
    setShowProjectEditModal(true);
  }, [setShowProjectEditModal, setShowConfirmationModel]);

  const handleChange = useCallback((changes) => {
    const newUpdatedProject = { ...project, ...changes };
    setProject(newUpdatedProject);
  }, [project, setProject]);

  const handleSaveProject = useCallback(async () => {
    const projectBody = {
      name: project.name,
      service_principals: project.service_principals.map((sp) => (sp.uuid)),
      users: project.users.map((u) => ({ user_uuid: u.user.uuid, auth_group: u.auth_group })),
    };
    const response = await putProject(authHeader, companyUuid, project.uuid, projectBody);
    if (response.status !== 200) {
      addAlert('danger', `Failed to update project '${project.name}'`);
      return;
    }
    const body = await response.json();
    setProject(body);
    handleProjectsChange(project.uuid, body);
    handleClose();
  }, [
    authHeader,
    companyUuid,
    project,
    handleClose,
    handleProjectsChange,
    addAlert,
  ]);

  if (!project) {
    return undefined;
  }

  return (
    <>
      <Modal show={showProjectEditModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Edit project
          </Modal.Title>
        </Modal.Header>
        {loading ? <Loader />
          : (
            <>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <Form.Group className="mb-3">
                    <Form.Label><strong>Project name</strong></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Project name"
                      value={project.name}
                      onInput={(e) => handleChange({ name: e.target.value })}
                    />
                  </Form.Group>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Group className="mb-3">
                    <Form.Label><strong>Service Principals</strong></Form.Label>
                    <EditProjectServicePrincipals
                      projectServicePrincipals={project.service_principals}
                      companyServicePrincipals={companyServicePrincipals}
                      handleProjectChange={handleChange}
                      companyUuid={companyUuid}
                      projectUuid={project.uuid}
                    />
                  </Form.Group>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Group className="mb-3">
                    <Form.Label><strong>Users</strong></Form.Label>
                    <ProjectUsers
                      companyUuid={companyUuid}
                      projectUuid={project.uuid}
                      companyUsers={companyUsers}
                      setProject={setProject}
                      project={project}
                    />
                  </Form.Group>
                </ListGroup.Item>
              </ListGroup>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={handleSaveProject}
                >
                  Save
                </Button>
                <Button
                  variant="danger"
                  onClick={handlePreDelete}
                  disabled={!authorization.project.delete}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
      </Modal>
      <Modal show={showConfirmationModal} onHide={handleCancelDelete}>
        <Modal.Body>
          Are you sure you want to delete project &apos;
          {project.name}
          &apos;?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancelDelete}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
