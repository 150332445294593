import React, { useState, useContext, useEffect } from 'react';
import {
  Alert, Breadcrumb,
} from 'react-bootstrap';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../../css/dashboard.css';
import getDashboardEmbed from '../../services/dashboards/getDashboardEmbed';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import Loader from '../generic/Loader';
import { AlertContext } from '../AlertProvider';

export default function Dashboard(props) {
  const {
    companyUuid,
    projectUuid,
    themeUuid,
    dashboardUuid,
  } = props;

  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  const [dashboard, setDashboard] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);

  async function fetchDashboardEmbed() {
    setLoaded(false);
    setMounted(false);
    const response = await getDashboardEmbed(
      authHeader,
      companyUuid,
      projectUuid,
      themeUuid,
      dashboardUuid,
    );
    if (response.status === 200) {
      const body = await response.json();
      setDashboard(body);
    }
    setLoaded(true);
  }

  useEffect(() => {
    if (authHeader && dashboardUuid) {
      fetchDashboardEmbed();
    }
  }, [authHeader, dashboardUuid]);

  const eventHandlers = new Map([
    ['loaded', () => {
      setMounted(true);
    }],
    ['error', (event) => {
      if (event?.detail?.technicalDetails?.errorInfo[0]?.value.includes('CapacityNotActive')) {
        addAlert('danger', 'Embedded capacity is not active, please contact your administrator.');
      } else {
        addAlert('danger', event.detail.detailedMessage);
      }
    }],
  ]);

  const dashboardConfig = (Boolean(dashboard.embed_url)
    && Boolean(dashboard.embed_token)
    && Boolean(dashboard.report_id)) ? ({
      type: 'report',
      id: dashboard.report_id,
      embedUrl: dashboard.embed_url,
      accessToken: dashboard.embed_token,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
        background: models.BackgroundType.Transparent,
      },
    })
    : null;

  const dashboardClass = mounted ? 'flex-grow-1 p-2' : 'flex-grow-1 p-2 d-none';

  const dashboardContent = (
    dashboardConfig ? (
      <PowerBIEmbed
        embedConfig={dashboardConfig}
        eventHandlers={eventHandlers}
        cssClassName={dashboardClass}
      />
    ) : (
      <Alert variant="warning" className="m-2">
        Can not display dashboard, make sure the configurations are properly set.
      </Alert>
    )
  );

  if (!loaded) {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <div>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="px-2 pt-2 " style={{ marginLeft: 47 }}>
        <Breadcrumb>
          <Breadcrumb.Item active>
            {dashboard.project.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {dashboard.theme.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {dashboard.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {dashboardContent}
      <div hidden={mounted}>
        <Loader />
      </div>
    </div>
  );
}
