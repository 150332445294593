export default async function postCompanyUser(authHeader, companyUuid, user) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'POST',
    body: JSON.stringify(user),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/users`;
  const response = await fetch(url, options);
  return response;
}
