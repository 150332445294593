import React from 'react';
import { Button } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import toolTip from './toolTip';

export default function addButton(subject, position, onClick) {
  return toolTip(
    `Add ${subject}`,
    position,
    <Button
      className="me-2"
      size="sm"
      onClick={onClick}
    >
      <PlusLg />
    </Button>,
  );
}
