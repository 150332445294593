import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import signUp from '../../services/authentication/singUp';
import routes from '../routes';

export default function SignUp() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailMsg, setShowFailMsg] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const response = await signUp(
        email,
        password,
        firstName,
        lastName,
      );
      if (response.status === 200) {
        setShowSuccessMsg(true);
      } else {
        setShowFailMsg(true);
      }
    }
  };

  const signUpForm = (
    <>
      <p>
        Want to be able to create your own dashboards? Contact support for a company account.
      </p>
      <p>
        Want to be able to see the dashboards to which you have been granted access?
        Go ahead and sign up below.
      </p>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div
          className="mb-2"
        >
          <div className="form-floating">
            <Form.Control
              id="emailInput"
              className="top"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Label htmlFor="emailInput">
              Email
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              Provide a valid email address.
            </Form.Control.Feedback>
          </div>
          <div className="form-floating">
            <Form.Control
              id="firstNameInput"
              className="middle"
              type="text"
              required
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Form.Label htmlFor="firstNameInput">
              First name
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              Enter your first name.
            </Form.Control.Feedback>
          </div>
          <div className="form-floating">
            <Form.Control
              id="lastNameInput"
              className="middle"
              type="text"
              required
              onChange={(e) => setLastName(e.target.value)}
            />
            <Form.Label htmlFor="lastNameInput">
              Last name
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              Enter your last name.
            </Form.Control.Feedback>
          </div>
          <div className="form-floating">
            <Form.Control
              id="passwordInput"
              className="bottom"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label htmlFor="passwordInput">
              Password
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              Enter a secure password.
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="d-grid gap-2">
          <Button size="lg" type="submit">Submit</Button>
        </div>
        <p>
          <NavLink to={routes.auth.login}>Already have an account? Go to login.</NavLink>
        </p>
      </Form>
    </>
  );

  const successMsg = (
    <p>
      Almost there!
      <br />
      We have sent a verification mail to
      {' '}
      {email}
      . It might have ended up in your spam box.
      Please click the link in the email to verify your email adress.
    </p>
  );

  const failMsg = (
    <p>
      Looks like your email is already in use.
      <br />
      <NavLink to={routes.auth.resetPassword}>Do you want to reset your password?</NavLink>
    </p>
  );

  const body = () => {
    if (showFailMsg) {
      return failMsg;
    } if (showSuccessMsg) {
      return successMsg;
    }
    return signUpForm;
  };

  return (
    <>
      <h1 className="h3 mb-3 font-weight-normal">Sign up</h1>
      {body()}
    </>
  );
}
