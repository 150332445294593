import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './auth/Login';

import Home from './home/Home';
import NoPage from './NoPage';
import AppLayout from './layouts/AppLayout';
import Logout from './auth/Logout';
import CompanyEdit from './companyEdit/CompanyEdit';
import SignUp from './auth/SignUp';
import AuthLayout from './auth/AuthLayout';
import Verify from './auth/Verify';
import ResetPassword from './auth/ResetPassword';
import ChangePassword from './auth/ChangePassword';
import routes from './routes';
import DashboardViewer from './dashboardViewer/DashboardViewer';
import UnderConstruction from './UnderConstruction';
import Contact from './support/Contact';
import HomeLayout from './layouts/HomeLayout';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path={routes.contact} index element={<Contact />} />
      </Route>
      <Route path="/" element={<AuthLayout />}>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.logout} element={<Logout />} />
        <Route path={routes.auth.signup} element={<SignUp />} />
        <Route path={routes.auth.signupVerify} element={<Verify />} />
        <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
        <Route path={routes.auth.changePassword} element={<ChangePassword />} />
      </Route>
      <Route path="/" element={<AppLayout />}>
        <Route path={routes.app.index} element={<DashboardViewer />} />
        <Route path={routes.app.dashboard} element={<DashboardViewer />} />
        <Route path={routes.app.company} element={<CompanyEdit />} />
        <Route path={routes.app.profile} element={<UnderConstruction />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}
