import React, { useContext } from 'react';
import {
  Row, Tab, Nav, Col,
} from 'react-bootstrap';

import Projects from './projectsEdit/Projects';
import ServicePrincipalList from './ServicePrincipalList';
import CompanyUserList from './CompanyUserList';
import { AuthorizationContext } from '../auth/AuthorizationProvider';
import Loader from '../generic/Loader';

export default function CompanyEditTabs(props) {
  const { company } = props;

  const { authorization, loading } = useContext(AuthorizationContext);

  if (loading) {
    return <Loader />;
  }

  const tabButtons = [];
  const tabContents = [];
  let defaultActiveKey;

  function addTab(tabContent, tabName) {
    const eventKey = tabName;
    tabButtons.push(
      <Nav.Item key={`${eventKey}TabButton`}>
        <Nav.Link eventKey={eventKey}>{tabName}</Nav.Link>
      </Nav.Item>,
    );
    tabContents.push(
      <Tab.Pane key={`${eventKey}TabContent`} eventKey={eventKey}>
        {tabContent}
      </Tab.Pane>,
    );
    if (!defaultActiveKey) {
      defaultActiveKey = eventKey;
    }
  }

  if (authorization.project.read) {
    addTab(
      <Projects
        companyUuid={company.uuid}
      />,
      'Projects',
    );
  }

  if (authorization.companyUser.read) {
    addTab(
      <CompanyUserList
        companyUuid={company.uuid}
      />,
      'Users',
    );
  }

  if (authorization.companyServicePrincipal.read) {
    addTab(
      <ServicePrincipalList
        companyUuid={company.uuid}
      />,
      'Service Principals',
    );
  }

  if (company) {
    return (
      <Col>
        <Tab.Container
          defaultActiveKey={defaultActiveKey}
          className="mb-3"
        >
          <Row className="mb-3">
            <Col>
              <Nav variant="pills">
                {tabButtons}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Tab.Content>
              {tabContents}
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Col>
    );
  }
}
