export default async function deleteCompanyUser(authHeader, companUuid, userUuid) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'DELETE',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/users/${userUuid}`;
  const response = await fetch(url, options);
  return response;
}
