import React, { useCallback } from 'react';
import { CaretRightFill } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';

function CollapseButtonLoad(props) {
  const {
    open,
    setOpen,
    setLoaded,
  } = props;

  const rotate = open ? 'rotate(90deg)' : 'rotate(0deg)';

  const handleEditCollapse = useCallback(() => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
      setLoaded(false);
    }
  });

  return (
    <CaretRightFill
      className="me-1 mb-1 align-self-center"
      onClick={handleEditCollapse}
      style={{ transform: rotate, transition: 'transform 0.3s ease' }}
    />
  );
}

CollapseButtonLoad.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setLoaded: PropTypes.func.isRequired,
};

export default CollapseButtonLoad;
