export default async function putDashboard(
  authHeader,
  companyUuid,
  projectUuid,
  themeUuid,
  dashboard,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'PUT',
    body: JSON.stringify(dashboard),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/themes/${themeUuid}/dashboards/${dashboard.uuid}`;
  return fetch(url, options);
}
