import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import putCompanyServicePrincipal from '../../services/companyServicePrincipal/putCompanyServicePrincipal';
import getCompanyServicePrincipal from '../../services/companyServicePrincipal/getCompanyServicePrincipal';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import '../../css/modal.css';
import Loader from '../generic/Loader';
import { AlertContext } from '../AlertProvider';

export default function CompanyServicePrincipalEditModal(props) {
  const {
    showState,
    setShowState,
    selectedServicePrincipalUuid,
    handleServicePrincipalChange,
    companyUuid,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);
  const [showSecret, setShowSecret] = useState(false);
  const [clientSecretType, setClientSecretType] = useState('password');
  const [servicePrincipal, setServicePrincipal] = useState();
  const [loadingBody, setLoadingBody] = useState(true);

  useEffect(() => {
    setLoadingBody(true);
  }, [
    selectedServicePrincipalUuid,
  ]);

  useEffect(() => {
    async function fetchServicePrincipal() {
      const response = await getCompanyServicePrincipal(
        authHeader,
        companyUuid,
        selectedServicePrincipalUuid,
      );
      if (response.status === 200) {
        const body = await response.json();
        setServicePrincipal(body);
      }
      setTimeout(() => setLoadingBody(false), 150);
    }
    fetchServicePrincipal();
  }, [
    authHeader,
    companyUuid,
    selectedServicePrincipalUuid,
  ]);

  const handleClose = useCallback(() => {
    setShowState(false);
  }, [setShowState, setLoadingBody]);

  const handleSave = useCallback(async () => {
    const response = await putCompanyServicePrincipal(
      authHeader,
      companyUuid,
      servicePrincipal.uuid,
      servicePrincipal,
    );
    if (response.status !== 200) {
      addAlert('danger', `Failed to update service principal '${servicePrincipal.name}'`);
      return;
    }
    handleServicePrincipalChange(servicePrincipal.uuid, servicePrincipal);
    handleClose();
  }, [
    authHeader,
    companyUuid,
    servicePrincipal,
    handleServicePrincipalChange,
  ]);

  const handleChange = useCallback((changes) => {
    const newServicePrincipal = { ...servicePrincipal, ...changes };
    setServicePrincipal(newServicePrincipal);
  }, [
    servicePrincipal,
    setServicePrincipal,
  ]);

  function handleShowSecret(show) {
    setShowSecret(show);
    if (show) {
      setClientSecretType('text');
    } else {
      setClientSecretType('password');
    }
  }

  return (
    <Modal
      show={showState}
      onHide={handleClose}
      backdropClassName="overlay-backdrop"
      id="editServicePrincipalModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Service Principal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingBody ? <Loader />
          : (
            <>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Service Principal Name"
                    value={servicePrincipal.name}
                    onChange={(e) => handleChange({ name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Tenant ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client ID"
                    value={servicePrincipal.tenant_id}
                    onChange={(e) => handleChange({ tenant_id: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Client ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client ID"
                    value={servicePrincipal.client_id}
                    onChange={(e) => handleChange({ client_id: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Client Secret
                    <EyeFill
                      className="ms-2"
                      onClick={() => handleShowSecret(true)}
                      hidden={showSecret}
                    />
                    <EyeSlashFill
                      className="ms-2"
                      onClick={() => handleShowSecret(false)}
                      hidden={!showSecret}
                    />
                  </Form.Label>
                  <Form.Control
                    type={clientSecretType}
                    placeholder="Client Secret"
                    value={servicePrincipal.client_secret}
                    onChange={(e) => handleChange({ client_secret: e.target.value })}
                  />
                </Form.Group>
              </Form>
              <div className="text-end">
                <Button className="text-end" variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </>
          )}
      </Modal.Body>
    </Modal>
  );
}
