export default async function resetPassword(email) {
  const resetBody = {
    email,
  };
  const headers = { 'Content-Type': 'application/json' };

  return fetch(`${process.env.REACT_APP_HELDR_API_URL}/password_reset`, {
    method: 'POST',
    body: JSON.stringify(resetBody),
    headers,
  });
}
