import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { ArrowReturnLeft } from 'react-bootstrap-icons';
import DashboardViewerSideBar from './dashboardViewerSideBar/DashboardViewerSideBar';
import Dashboard from './Dashboard';
import routes from '../routes';
import { SideBarContext } from '../layouts/SideBarContentProvider';

export default function DashboardViewer() {
  const {
    dashboardUuid,
    themeUuid,
    projectUuid,
    companyUuid,
  } = useParams();
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState(companyUuid);
  const [selectedProjectUuid, setSelectedProjectUuid] = useState(projectUuid);
  const [selectedThemeUuid, setSelectedThemeUuid] = useState(themeUuid);
  const [selectedDashboardUuid, setSelectedDashboardUuid] = useState(dashboardUuid);
  const navigate = useNavigate();
  const { setSidebarContent } = useContext(SideBarContext);

  const setDashboardSettings = useCallback(
    (
      newCompanyUuid,
      newProjectUuid,
      newThemeUuid,
      newDashboardUuid,
    ) => {
      setSelectedCompanyUuid(newCompanyUuid);
      setSelectedProjectUuid(newProjectUuid);
      setSelectedThemeUuid(newThemeUuid);
      setSelectedDashboardUuid(newDashboardUuid);
      const url = routes.app.dashboard.replace(':dashboardUuid', newDashboardUuid).replace(':themeUuid', newThemeUuid).replace(':projectUuid', newProjectUuid).replace(':companyUuid', newCompanyUuid);
      navigate(url);
    },
    [
      setSelectedCompanyUuid,
      setSelectedProjectUuid,
      setSelectedThemeUuid,
      setSelectedDashboardUuid,
    ],
  );

  useEffect(() => {
    setSidebarContent(
      <DashboardViewerSideBar
        setDashboard={setDashboardSettings}
      />,
    );
  }, [setSidebarContent, setDashboardSettings]);

  if (selectedDashboardUuid) {
    return (
      <Dashboard
        companyUuid={selectedCompanyUuid}
        projectUuid={selectedProjectUuid}
        themeUuid={selectedThemeUuid}
        dashboardUuid={selectedDashboardUuid}
      />
    );
  }
  return (
    <Col className="align-self-center">
      <Row className="text-center">
        <h3> Select a dashboard from the menu. </h3>
        <ArrowReturnLeft size={80} />
      </Row>
    </Col>
  );
}
