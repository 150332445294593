import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProjectItem from './ProjectItem';

export default function ProjectItems(params) {
  const {
    projects,
    setDashboard,
  } = params;

  if (projects.length === 0) {
    return (
      <Row>
        <div style={{ width: 20 }} />
        <Col>
          <span className="text-muted">
            No projects
          </span>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      {projects.map((project) => (
        <ProjectItem
          key={project.uuid}
          project={project}
          setDashboard={setDashboard}
        />
      ))}
    </Row>
  );
}
