import React, { useState, useContext } from 'react';
import {
  ListGroup, Row, Col, Collapse, Container, Spinner,
} from 'react-bootstrap';
import { Diagram3Fill } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import addButton from '../../generic/addButton';
import editButton from '../../generic/editButton';
import CollapseButtonLoad from '../../generic/CollapseButtonLoad';
import Themes from './theme/Themes';
import postTheme from '../../../services/themes/postTheme';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';

export default function Project(props) {
  const {
    project,
    companyUuid,
    handleEditProject,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);

  const [open, setOpen] = useState(false);
  const [selectedThemeUuid, setSelectedThemeUuid] = useState();
  const [loaded, setLoaded] = useState(false);
  const [showThemeEdit, setShowThemeEdit] = useState(false);
  const [projectThemes, setProjectThemes] = useState([]);

  async function handleCreateTheme() {
    const theme = { name: 'New Theme', uuid: uuidv4() };
    const response = await postTheme(authHeader, companyUuid, project.uuid, theme);
    if (response.status === 200) {
      setSelectedThemeUuid(theme.uuid);
      setShowThemeEdit(true);
      const newProjectThemes = [...projectThemes, theme];
      setProjectThemes(newProjectThemes);
    }
  }
  const themes = open
    ? (
      <Themes
        selectedThemeUuid={selectedThemeUuid}
        companyUuid={companyUuid}
        projectUuid={project.uuid}
        loaded={loaded}
        showThemeEdit={showThemeEdit}
        setSelectedThemeUuid={setSelectedThemeUuid}
        setLoaded={setLoaded}
        setShowThemeEdit={setShowThemeEdit}
        themes={projectThemes}
        setThemes={setProjectThemes}
      />
    ) : undefined;

  const loadingSpinner = open && !loaded ? (
    <Spinner
      className="ms-2"
      animation="border"
      size="sm"
      variant="primary"
    />
  ) : undefined;

  const addThemeButton = addButton(
    'Theme',
    'left',
    () => handleCreateTheme(),
  );

  const editProjectButton = editButton(
    'Project',
    'right',
    () => handleEditProject(project.uuid),
  );
  return (
    <ListGroup.Item key={project.uuid}>
      <Container>
        <Row className="mb-2">
          <Col className="align-self-center">
            <CollapseButtonLoad
              open={open}
              setOpen={setOpen}
              setLoaded={setLoaded}
            />
            <span className="ms-2">
              <Diagram3Fill className="me-1" />
              {project.name}
            </span>
            {loadingSpinner}

          </Col>
          <Col className="text-end">
            {addThemeButton}
            {editProjectButton}
          </Col>
        </Row>
        <Collapse in={loaded}>
          <div className="ms-4">
            {themes}
          </div>
        </Collapse>
      </Container>
    </ListGroup.Item>
  );
}
