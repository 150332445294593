import React from 'react';
import { Button } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import toolTip from './toolTip';

export default function editButton(subject, position, onClick) {
  return toolTip(
    `Edit ${subject}`,
    position,
    <Button
      className="me-2"
      size="sm"
      onClick={onClick}
    >
      <PencilSquare />
    </Button>,
  );
}
