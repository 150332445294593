export default async function putProject(authHeader, companyUuid, projectUuid, project) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'PUT',
    body: JSON.stringify(project),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}`;
  const response = fetch(url, options);
  return response;
}
