export default async function postProjectServicePrincipal(
  authHeader,
  companUuid,
  projectUuid,
  servicePrincipal,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'POST',
    body: JSON.stringify(servicePrincipal),
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/projects/${projectUuid}/service_principals`;
  const response = await fetch(url, options);
  return response;
}
