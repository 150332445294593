import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { BarChartFill } from 'react-bootstrap-icons';

export default function DashboardItem(params) {
  const {
    dashboard,
    companyUuid,
    projectUuid,
    themeUuid,
    setDashboard,
  } = params;

  function setDashboardSettings() {
    setDashboard(companyUuid, projectUuid, themeUuid, dashboard.uuid);
  }

  return (
    <Nav.Item>
      <Nav.Link onClick={() => setDashboardSettings()}>
        <Row>
          <div style={{ width: 20 }}>
            <BarChartFill />
          </div>
          <Col>
            {dashboard.name}
          </Col>
        </Row>
      </Nav.Link>
    </Nav.Item>
  );
}
