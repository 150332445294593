export default async function getCompanyUsers(
  authHeader,
  companUuid,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'get',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/users`;
  return fetch(url, options);
}
