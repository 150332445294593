const routes = {
  home: '/',
  contact: '/contact',
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    signup: '/auth/signup',
    signupVerify: '/auth/signup/verify/:userUuid/:token',
    resetPassword: '/auth/reset-password',
    changePassword: '/auth/change-password/:userUuid/:token',
  },
  app: {
    index: '/app',
    dashboard: '/app/companies/:companyUuid/projects/:projectUuid/themes/:themeUuid/dashboards/:dashboardUuid',
    company: '/app/companies/:companyUuid',
    profile: '/app/profile',
  },
};

export default routes;
