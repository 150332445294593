export default async function signUp(email, password, firstname, lastname) {
  const signUpBody = {
    email,
    password,
    first_name: firstname,
    last_name: lastname,
  };
  const headers = { 'Content-Type': 'application/json' };

  return fetch(`${process.env.REACT_APP_HELDR_API_URL}/signup`, {
    method: 'POST',
    body: JSON.stringify(signUpBody),
    headers,
  });
}
