export default async function getCompanyServicePrincipal(
  authHeader,
  companUuid,
  servicePrincipalUuid,
) {
  const headers = authHeader;
  headers['Content-Type'] = 'application/json';
  const options = {
    method: 'get',
    headers,
  };
  const url = `${process.env.REACT_APP_HELDR_API_URL}/companies/${companUuid}/service_principals/${servicePrincipalUuid}`;
  return fetch(url, options);
}
