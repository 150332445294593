import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import changePassword from '../../services/authentication/changePassword';
import routes from '../routes';

export default function ChangePassword() {
  const { userUuid, token } = useParams();
  const [password, setPassword] = useState();
  const [passwordVerify, setPasswordVerify] = useState();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailMsg, setShowFailMsg] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (password !== passwordVerify) {
      setValidated(true);
    }
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    const response = await changePassword(
      userUuid,
      token,
      password,
    );
    if (response.status === 200) {
      setShowSuccessMsg(true);
    } else {
      setShowFailMsg(true);
    }
  };

  const changePasswordForm = (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <div
        className="mb-2"
      >
        <div className="form-floating">
          <Form.Control
            id="passwordInput"
            className="top"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Label htmlFor="passwordInput">
            Password
          </Form.Label>
        </div>
        <div className="form-floating">
          <Form.Control
            id="passwordInput"
            className="bottom"
            type="password"
            required
            onChange={(e) => setPasswordVerify(e.target.value)}
          />
          <Form.Label htmlFor="passwordInput">
            Confirm Password
          </Form.Label>
          <Form.Control.Feedback type="invalid">
            The passwords do not match.
          </Form.Control.Feedback>
        </div>
      </div>
      <Button
        className="mb-2"
        type="submit"
      >
        Reset Password
      </Button>
    </Form>

  );

  const successMsg = (
    <div>
      <p>
        Your password has been changed.
        <br />
        <Link to={routes.auth.login}>
          You can now log in!
        </Link>
      </p>
    </div>
  );

  const failMsg = (
    <div>
      <p>
        Something went wrong.
      </p>
      <p>
        Please contact support.
      </p>
    </div>
  );

  if (showSuccessMsg) {
    return successMsg;
  }
  if (showFailMsg) {
    return failMsg;
  }

  return changePasswordForm;
}
