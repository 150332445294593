import React, { useEffect, useContext, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Dashboard from './Dashboard';
import getDashboards from '../../../../services/dashboards/getDashboards';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import DashboardEdit from './DashboardEdit';

export default function Dashboards(props) {
  const {
    dashboards,
    themeUuid,
    projectUuid,
    companyUuid,
    setLoaded,
    loaded,
    selectedDashboardUuid,
    setSelectedDashboardUuid,
    showDashboardEdit,
    setShowDashboardEdit,
    setDashboards,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  useEffect(() => {
    async function fetchDashboards() {
      const response = await getDashboards(
        authHeader,
        companyUuid,
        projectUuid,
        themeUuid,
      );
      if (response.status === 200) {
        const body = await response.json();
        setDashboards(body);
      }
      setLoaded(true);
    }
    fetchDashboards();
  }, [authHeader,
    companyUuid,
    projectUuid,
    themeUuid]);

  const setDashboardName = useCallback((uuid, dashboardName) => {
    const newDashboards = [...dashboards];
    const index = newDashboards.findIndex((d) => d.uuid === uuid);
    newDashboards[index].name = dashboardName;
    setDashboards(newDashboards);
  }, [dashboards, setDashboards]);

  const setDeleteDashboard = useCallback((uuid) => {
    const newDashboards = dashboards.filter((d) => d.uuid !== uuid);
    setDashboards(newDashboards);
  }, [dashboards, setDashboards]);

  if (!loaded) {
    return undefined;
  }

  return (
    <>
      <div className="ms-4">
        {dashboards.length ? dashboards.map((dashboard) => (
          <Dashboard
            key={dashboard.uuid}
            dashboard={dashboard}
            themeUuid={themeUuid}
            projectUuid={projectUuid}
            companyUuid={companyUuid}
            setShowDashboardEdit={setShowDashboardEdit}
            setSelectedDashboardUuid={setSelectedDashboardUuid}
          />
        )) : (
          <Row className="mb-2">
            <Col>
              <span className="fst-italic">No dashboards</span>
            </Col>
          </Row>
        )}
      </div>
      {selectedDashboardUuid ? (
        <DashboardEdit
          dashboardUuid={selectedDashboardUuid}
          setDashboardUuid={setSelectedDashboardUuid}
          themeUuid={themeUuid}
          projectUuid={projectUuid}
          companyUuid={companyUuid}
          showDashboardEdit={showDashboardEdit}
          setShowDashboardEdit={setShowDashboardEdit}
          setDashboardName={setDashboardName}
          setDeleteDashboard={setDeleteDashboard}
        />
      ) : undefined}
    </>
  );
}
