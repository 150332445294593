import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticationContext } from './AuthenticationProvider';

import routes from '../routes';

export default function Logout() {
  const { setToken } = useContext(AuthenticationContext);
  setToken({});
  return (
    <Navigate to={routes.auth.login} />
  );
}
