import React, { useContext, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import getPowerBiWorkspaces from '../../../../services/dashboards/getPowerBiGroups';

export default function SelectWorkspace(props) {
  const {
    handleChangeDashboard,
    dashboardWorkspaceUuid,
    dashboardServicePrincipalUuid,
    projectUuid,
    companyUuid,
  } = props;

  const { authHeader } = useContext(AuthenticationContext);
  const [dashboardWorkspaces, setDashboardWorkspaces] = useState([]);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(true);
  const [validServicePrincipal, setValidServicePrincipal] = useState(true);

  useEffect(() => {
    async function fetchWorkspaces() {
      if (dashboardServicePrincipalUuid) {
        const response = await getPowerBiWorkspaces(
          authHeader,
          companyUuid,
          projectUuid,
          dashboardServicePrincipalUuid,
        );
        const body = await response.json();
        if (!response.ok && body.detail.includes('Invalid service principal')) {
          setValidServicePrincipal(false);
        }
        if (response.status === 200) {
          setValidServicePrincipal(true);
          setDashboardWorkspaces(body);
        }
        setLoadingWorkspaces(false);
      }
    }
    fetchWorkspaces();
  }, [
    authHeader,
    companyUuid,
    projectUuid,
    dashboardServicePrincipalUuid,
  ]);

  function handleChangeWorkspace(event) {
    const group_id = event.target.value !== '0' ? event.target.value : null; // eslint-disable-line camelcase
    handleChangeDashboard({ group_id, report_id: null }); // eslint-disable-line camelcase
  }

  let selectWorkspace;
  if (!loadingWorkspaces) {
    if (validServicePrincipal) {
      selectWorkspace = (
        <Form.Select
          className="mb-3"
          placeholder="Select workspace"
          value={dashboardWorkspaceUuid}
          onChange={(e) => { handleChangeWorkspace(e); }}
        >
          <option value="0">-</option>
          {dashboardWorkspaces.map((workspace) => (
            <option key={workspace.id} value={workspace.id}>
              {workspace.name}
            </option>
          ))}
        </Form.Select>
      );
    } else {
      selectWorkspace = (
        <Form.Select
          className="mb-3"
          disabled
        >
          <option>
            Invalid service principal
          </option>
        </Form.Select>
      );
    }
  } else {
    selectWorkspace = (
      <Form.Select
        className="mb-3"
        disabled
      >
        <option>
          Loading workspaces
        </option>
      </Form.Select>
    );
  }

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Workspaces</Form.Label>
      {selectWorkspace}
    </Form.Group>
  );
}
