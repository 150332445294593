import React, {
  useContext, useCallback, useState, useEffect,
} from 'react';
import {
  Form, Row, Col, Button, ListGroup,
} from 'react-bootstrap';
import { Trash3Fill } from 'react-bootstrap-icons';
import postProjectServicePrincipal from '../../../services/projectServicePrincipal/postProjectServicePrincipal';
import deleteProjectServicePrincipal from '../../../services/projectServicePrincipal/deleteProjectServicePrincipal';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import { AuthorizationContext } from '../../auth/AuthorizationProvider';
import { AlertContext } from '../../AlertProvider';

export default function ProjectServicePrincipals(props) {
  const {
    projectServicePrincipals,
    companyServicePrincipals,
    handleProjectChange,
    companyUuid,
    projectUuid,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { authorization } = useContext(AuthorizationContext);
  const { addAlert } = useContext(AlertContext);
  const [projectServicePrincipalOptions, setProjectServicePrincipalOptions] = useState([]);

  useEffect(() => {
    const newProjectServicePrincipalOptions = [];
    companyServicePrincipals.forEach((servicePrincipal) => {
      const isProjectServicePrincipal = projectServicePrincipals.find(
        (sp) => sp.uuid === servicePrincipal.uuid,
      );
      if (!isProjectServicePrincipal) {
        newProjectServicePrincipalOptions.push(servicePrincipal);
      }
    });
    setProjectServicePrincipalOptions(newProjectServicePrincipalOptions);
  }, [companyServicePrincipals, projectServicePrincipals]);

  const handleAddServicePrincipal = useCallback(async ({ servicePrincipal }) => {
    const response = await postProjectServicePrincipal(
      authHeader,
      companyUuid,
      projectUuid,
      { uuid: servicePrincipal.uuid },
    );
    if (response.status !== 200) {
      addAlert('danger', `Failed to add service principal '${servicePrincipal.name}'`);
      return;
    }
    const newProjectServicePrincipals = [...projectServicePrincipals];
    newProjectServicePrincipals.push(servicePrincipal);
    handleProjectChange({ service_principals: newProjectServicePrincipals });
    setProjectServicePrincipalOptions(projectServicePrincipalOptions.filter(
      (sp) => sp.uuid !== servicePrincipal.uuid,
    ));
  }, [
    authHeader,
    companyUuid,
    projectUuid,
    projectServicePrincipals,
    handleProjectChange,
  ]);

  const handleDeleteServicePrincipal = useCallback(
    (servicePrincipal) => {
      deleteProjectServicePrincipal(
        authHeader,
        companyUuid,
        projectUuid,
        servicePrincipal.uuid,
      );
      const newProjectServicePrincipals = projectServicePrincipals.filter(
        (sp) => sp.uuid !== servicePrincipal.uuid,
      );
      handleProjectChange({ service_principals: newProjectServicePrincipals });
    },
    [
      authHeader,
      companyUuid,
      projectUuid,
      projectServicePrincipals,
      handleProjectChange,
    ],
  );

  function handleChangeServicePrincipal(event) {
    const selectedServicePrincipalUuid = event.target.value;
    const selectedServicePrincipal = companyServicePrincipals.find(
      (sp) => sp.uuid === selectedServicePrincipalUuid,
    );
    const isProjectServicePrincipal = projectServicePrincipals.find(
      (sp) => sp.uuid === selectedServicePrincipalUuid,
    );
    if (!isProjectServicePrincipal) {
      handleAddServicePrincipal({ servicePrincipal: selectedServicePrincipal });
    }
  }

  if (companyServicePrincipals.length) {
    return (
      <>
        <Form.Select
          className="mb-3"
          type="text"
          placeholder="Select project service principal"
          onChange={(event) => handleChangeServicePrincipal(event)}
          value=""
          disabled={!authorization.projectServicePrincipal.create}
        >
          <>
            <option value="" disabled hidden>
              Select project service principal
            </option>
            {projectServicePrincipalOptions.map((servicePrincipal) => (
              <option key={servicePrincipal.uuid} value={servicePrincipal.uuid}>
                {servicePrincipal.name}
              </option>
            ))}
          </>
        </Form.Select>
        <ListGroup>
          {projectServicePrincipals.map((projectServicePrincipal) => (
            <ListGroup.Item key={projectServicePrincipal.uuid} variant="light">
              <Row>
                <Col className="align-self-center" xs="10">
                  {projectServicePrincipal.name}
                </Col>
                <Col className="text-end align-self-center" xs="2">
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteServicePrincipal(projectServicePrincipal)}
                    disabled={!authorization.projectServicePrincipal.delete}
                  >
                    <Trash3Fill />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </>
    );
  }
  return (
    <Form.Text
      disabled
      placeholder="No service principals. Add new service principals in the Service Principals tab."
    />
  );
}
