import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loader() {
  return (
    <div className="text-center m-5">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}
