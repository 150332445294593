import React, { useContext, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import getPowerBiReports from '../../../../services/dashboards/getPowerBiReports';

export default function SelectReport(props) {
  const {
    dashboardReportUuid,
    dashboardWorkspaceUuid,
    dashboardServicePrincipalUuid,
    projectUuid,
    companyUuid,
    handleChangeDashboard,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const [loadingReports, setLoadingReports] = useState([]);
  const [reports, setReports] = useState([]);
  const [validServicePrincipal, setValidServicePrincipal] = useState(true);

  useEffect(() => {
    async function fetchReports() {
      if (dashboardServicePrincipalUuid && dashboardWorkspaceUuid) {
        setLoadingReports(true);
        const response = await getPowerBiReports(
          authHeader,
          companyUuid,
          projectUuid,
          dashboardServicePrincipalUuid,
          dashboardWorkspaceUuid,
        );
        const body = await response.json();
        if (response.status === 200) {
          setReports(body);
          setValidServicePrincipal(true);
        } else {
          setValidServicePrincipal(false);
        }
        setLoadingReports(false);
      }
    }

    fetchReports();
  }, [
    authHeader,
    companyUuid,
    projectUuid,
    dashboardServicePrincipalUuid,
    dashboardWorkspaceUuid,
  ]);

  function handleReportChange(event) {
    const report_id = event.target.value !== '0' ? event.target.value : null; // eslint-disable-line camelcase
    handleChangeDashboard({ report_id }); // eslint-disable-line camelcase
  }

  let selectReport;
  if (!loadingReports) {
    if (validServicePrincipal) {
      selectReport = (
        <Form.Select
          className="mb-3"
          placeholder="Select report"
          value={dashboardReportUuid}
          onChange={(e) => { handleReportChange(e); }}
        >
          <option value="0">-</option>
          {reports.map((report) => (
            <option key={report.id} value={report.id}>
              {report.name}
            </option>
          ))}
        </Form.Select>
      );
    } else {
      selectReport = (
        <Form.Select
          className="mb-3"
          disabled
        >
          <option>
            Invalid service principal
          </option>
        </Form.Select>
      );
    }
  } else {
    selectReport = (
      <Form.Select
        className="mb-3"
        disabled
      >
        <option>
          Loading reports
        </option>
      </Form.Select>
    );
  }

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Reports</Form.Label>
      {selectReport}
    </Form.Group>
  );
}
