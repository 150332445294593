import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { AuthenticationContext } from '../../../auth/AuthenticationProvider';
import putTheme from '../../../../services/themes/putTheme';
import deleteTheme from '../../../../services/themes/deleteTheme';
import getTheme from '../../../../services/themes/getTheme';
import Loader from '../../../generic/Loader';
import { AlertContext } from '../../../AlertProvider';

export default function ThemeEdit(props) {
  const {
    themeUuid,
    projectUuid,
    companyUuid,
    setThemeName,
    showThemeEdit,
    setShowThemeEdit,
    setDeleteTheme,
  } = props;

  const { authHeader } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  const [showConfirmationModal, setShowConfirmationModel] = useState(false);
  const [theme, setTheme] = useState();
  const [loadingBody, setLoadingBody] = useState(true);

  useEffect(() => {
    setLoadingBody(true);
  }, [
    themeUuid,
  ]);
  useEffect(() => {
    async function fetchTheme() {
      const response = await getTheme(
        authHeader,
        companyUuid,
        projectUuid,
        themeUuid,
      );
      if (response.status === 200) {
        const body = await response.json();
        setTheme(body);
      }
      setTimeout(() => {
        setLoadingBody(false);
      }, 250);
    }
    fetchTheme();
  }, [
    authHeader,
    companyUuid,
    projectUuid,
    themeUuid,
  ]);

  const handleChange = useCallback((changes) => {
    const newTheme = { ...theme, ...changes };
    setTheme(newTheme);
  }, [
    theme,
    setTheme,
  ]);

  const handleClose = useCallback(() => {
    setShowThemeEdit(false);
  }, [
    setShowThemeEdit,
  ]);

  const handleSave = useCallback(async () => {
    const response = await putTheme(authHeader, companyUuid, projectUuid, theme);
    if (response.status !== 200) {
      addAlert('danger', `Failed to save theme '${theme.name}'`);
      return;
    }
    setThemeName(theme.uuid, theme.name);
    handleClose();
  }, [authHeader, companyUuid, projectUuid, theme, handleClose]);

  const handlePreDelete = useCallback(() => {
    setShowThemeEdit(false);
    setShowConfirmationModel(true);
  }, [
    setShowThemeEdit,
    setShowConfirmationModel,
  ]);

  const handleConfirmDelete = useCallback(() => {
    deleteTheme(authHeader, companyUuid, projectUuid, theme.uuid);
    setDeleteTheme(theme.uuid);
    setShowConfirmationModel(false);
  }, [
    theme,
    setDeleteTheme,
    setShowConfirmationModel,
  ]);

  const handleCancelDelete = useCallback(() => {
    setShowConfirmationModel(false);
    setShowThemeEdit(true);
  }, [
    setShowThemeEdit,
    setShowConfirmationModel,
  ]);

  if (!theme) {
    return undefined;
  }

  return (
    <>
      <Modal show={showThemeEdit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit theme
          </Modal.Title>
        </Modal.Header>
        {loadingBody ? <Loader />
          : (
            <>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Theme name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Theme name"
                    value={theme.name}
                    onInput={(e) => handleChange({ name: e.target.value })}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button variant="danger" onClick={handlePreDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
      </Modal>
      <Modal show={showConfirmationModal} onHide={handleCancelDelete}>
        <Modal.Body>
          Are you sure you want to delete theme &apos;
          {theme.name}
          &apos;?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancelDelete}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
