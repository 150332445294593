import React, { useState, useEffect, useContext } from 'react';
import {
  Navbar, Row, Container, Image, Offcanvas, Button, Col, Collapse,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CaretRightFill, CaretLeftFill } from 'react-bootstrap-icons';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import getMyCompanies from '../../services/companies/getMyCompanies';
import ProfileNavItems from './navbar/ProfileNavItems';
import { SideBarContext } from './SideBarContentProvider';
import logoImg from '../../media/held-r-logo.png';
import routes from '../routes';

export default function SideBar() {
  const [companies, setCompanies] = useState([]);
  const { authHeader } = useContext(AuthenticationContext);
  const { sidebarContent } = useContext(SideBarContext);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
    setShowSideBar(false);
  };
  const handleShowOffCanvas = () => {
    setShowOffCanvas(true);
    setShowSideBar(true);
  };

  useEffect(() => {
    async function fetchCompanies() {
      const response = await getMyCompanies(authHeader);
      if (response.status === 200) {
        const body = await response.json();
        setCompanies(body);
      }
    }
    if (authHeader) {
      fetchCompanies();
    }
  }, [authHeader]);

  return (
    <>
      <div style={{ position: 'absolute' }}>
        <Button
          variant="primary"
          className="shadow"
          onClick={handleShowOffCanvas}
          style={
            { position: 'absolute', left: 5, top: 5 }
          }
        >
          <CaretRightFill />
        </Button>
      </div>
      <Offcanvas
        show={showOffCanvas}
        onHide={handleCloseOffCanvas}
        onShow={handleShowOffCanvas}
        responsive="lg"
      >
        <Collapse in={showSideBar} dimension="width">
          <Navbar className="p-0">
            <div
              style={
                {
                  width: 'var(--bs-offcanvas-width)',
                  height: '100vh',
                  maxHeight: '100vh',
                  overflowY: 'auto',
                }
              }
              className="border-end bg-white innerscroll"
            >
              <Container className="p-0 mb-5">
                <Navbar.Brand>
                  <Row className="py-3 border-bottom align-items-center">
                    <Col md={10} className="d-flex justify-content-center">
                      <Link to={routes.home}>
                        <Image src={logoImg} className="logo-header" />
                      </Link>
                    </Col>
                    <Col md={2} className="text-end">
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={handleCloseOffCanvas}
                      >
                        <CaretLeftFill />
                      </Button>
                    </Col>
                  </Row>
                </Navbar.Brand>
                <Row className="border-bottom">
                  {sidebarContent}
                </Row>
                <Row className="border-bottom">
                  <ProfileNavItems
                    companies={companies}
                  />
                </Row>
              </Container>
            </div>
          </Navbar>
        </Collapse>
      </Offcanvas>
    </>
  );
}
