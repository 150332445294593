export default async function getPowerBiReports(
  authHeader,
  companyUuid,
  projectUuid,
  servicePrincipalUuid,
  groupId,
) {
  return fetch(
    `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/service_principals/${servicePrincipalUuid}/groups/${groupId}/reports`,
    { headers: authHeader },
  );
}
