import React from 'react';
import { Col, Row } from 'react-bootstrap';

import DashboardItem from './DashboardItem';

export default function DashboardItems(params) {
  const {
    dashboards,
    companyUuid,
    projectUuid,
    themeUuid,
    setDashboard,
  } = params;

  if (dashboards.length === 0) {
    return (
      <Row>
        <div style={{ width: 20 }} />
        <Col>
          <span className="text-muted">
            No Dashboards
          </span>
        </Col>
      </Row>
    );
  }
  return (
    <>
      {
        dashboards.map((dashboard) => (
          <DashboardItem
            key={dashboard.uuid}
            dashboard={dashboard}
            companyUuid={companyUuid}
            projectUuid={projectUuid}
            themeUuid={themeUuid}
            setDashboard={setDashboard}
          />
        ))
      }
    </>
  );
}
