import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import verifyUserReq from '../../services/authentication/verify';
import routes from '../routes';
import Loader from '../generic/Loader';

export default function Verify() {
  const { userUuid, token } = useParams();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function verifyUser() {
      const response = await verifyUserReq(userUuid, token);
      if (response.status === 200) {
        setShowSuccessMsg(true);
      } else {
        setShowSuccessMsg(false);
      }
      setLoading(false);
    }
    verifyUser();
  }, [userUuid, token, setShowSuccessMsg]);

  if (loading) {
    return (
      <div>
        <p>
          Verifying account...
        </p>
        <Loader />
      </div>
    );
  }

  if (showSuccessMsg) {
    return (
      <div>
        <p>
          Your account has been verified.
        </p>
        <Link to={routes.auth.login}>
          You can now log in!
        </Link>
      </div>
    );
  }
  return (
    <div>
      <p>
        Your account could not be verified.
      </p>
      <p>
        Please contact support.
      </p>
    </div>
  );
}
