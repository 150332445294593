import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

export const AuthorizationContext = React.createContext();

export default function AuthorizationProvider(props) {
  const {
    authorizationFunction,
    authorizationFunctionArgs,
    children,
  } = props;

  const [authorization, setAuthorization] = useState({});
  const [loading, setLoading] = useState(true);
  const { authHeader } = useContext(AuthenticationContext);

  const AuthorizationContextValue = useMemo(() => ({
    authorization,
    setAuthorization,
    loading,
  }), [
    authorization,
    setAuthorization,
    loading,
  ]);

  useEffect(() => {
    async function fetchAuth() {
      const response = await authorizationFunction(
        { authHeader, ...authorizationFunctionArgs },
      );
      if (response.status === 200) {
        const body = await response.json();
        setAuthorization(body);
      }
      setLoading(false);
    }
    fetchAuth();
  }, [
    setAuthorization,
    setLoading,
  ]);

  return (
    <AuthorizationContext.Provider value={AuthorizationContextValue}>
      {children}
    </AuthorizationContext.Provider>
  );
}
