export default async function getPowerBiGroups(
  authHeader,
  companyUuid,
  projectUuid,
  servicePrincipalUuid,
) {
  return fetch(
    `${process.env.REACT_APP_HELDR_API_URL}/companies/${companyUuid}/projects/${projectUuid}/service_principals/${servicePrincipalUuid}/groups`,
    { headers: authHeader },
  );
}
