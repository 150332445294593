import React from 'react';
import {
  Container, Image, Row, Col,
} from 'react-bootstrap';
import underConstruction from '../media/under_construction.png';

export default function UnderConstruction() {
  return (
    <Container className="text-center">
      <Row>
        <Col>
          <Row className="my-5">
            <h1>
              Work in progress
            </h1>
            <h2>
              This is not ready yet, but it will be soon!
            </h2>
          </Row>
          <Image src={underConstruction} alt="Under Construction" roundedCircle fluid />
        </Col>
      </Row>
    </Container>
  );
}
