import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function SideBarSpinner() {
  return (
    <div className="ms-5">
      <Spinner animation="border" variant="primary" size="sm" />
    </div>
  );
}
