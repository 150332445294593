import React, { useState, useEffect } from 'react';
import {
  Form, Row, Col, Button, ListGroup,
} from 'react-bootstrap';
import { Trash3Fill } from 'react-bootstrap-icons';

export default function ProjectUsers(props) {
  const {
    companyUsers,
    setProject,
    project,
  } = props;

  const [projectUserOptions, setProjectUserOptions] = useState([]);

  useEffect(() => {
    const newProjectUserOptions = [];
    companyUsers.forEach((companyUser) => {
      const isProjectUser = project.users.find(
        (pu) => pu.user.uuid === companyUser.user.uuid,
      );
      if (!isProjectUser) {
        newProjectUserOptions.push(companyUser);
      }
    });
    setProjectUserOptions(newProjectUserOptions);
  }, [companyUsers, project.users]);

  async function handleChangeDeleteUser(userUuid) {
    const newProjectUsers = project.users.filter((u) => u.user.uuid !== userUuid);
    setProject({ ...project, users: newProjectUsers });
  }

  async function handleAddUser(event) {
    const selectedCompanyUser = companyUsers.find((u) => u.user.uuid === event.target.value);
    const selectedUser = selectedCompanyUser.user;
    const newProjectUsers = [...project.users, { user: { ...selectedUser }, auth_group: 'Member' }];
    setProject({ ...project, users: newProjectUsers });
  }

  if (companyUsers.length) {
    return (
      <>
        <Form.Select
          className="mb-3"
          type="email"
          placeholder="Select project members"
          onChange={(event) => handleAddUser(event)}
          value=""
        >
          <option value="" disabled hidden>Select members</option>
          {projectUserOptions.map((option) => (
            <option key={option.user.email} value={option.user.uuid}>{option.user.email}</option>
          ))}
        </Form.Select>
        <ListGroup>
          {project.users.map((projectUser) => {
            const { user } = projectUser;
            return (
              <ListGroup.Item key={user.uuid} variant="light">
                <Row>
                  <Col className="align-self-center" xs="12" md="10">
                    {user.email}
                  </Col>
                  <Col className="text-end align-self-center">
                    <Button variant="danger" size="sm" onClick={() => handleChangeDeleteUser(user.uuid)}><Trash3Fill /></Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  }
  return (
    <Form.Text
      disabled
      placeholder="No company users. Add new users in the Users tab."
    />
  );
}
