import React from 'react';
import {
  Container, Row, Col, Button, Image,
} from 'react-bootstrap';
import { BarChartFill, Shuffle, PersonFillLock } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import lightLogo from '../../media/logo-light.svg';
import superUser from '../../media/super-user.png';
import FactCard from './factCard';
import routes from '../routes';

export default function Home() {
  const callToActionMaxHeight = '810px';
  const navBarHeight = '32px';
  const callToActionHeight = `calc(100vh - ${navBarHeight})`;
  const callToActionMinHeight = `calc(700px - ${navBarHeight})`;
  const iconsSize = 60;

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <div
            className="rounded  d-flex justify-content-end"
            style={{
              position: 'relative',
              height: callToActionHeight,
              'max-height': callToActionMaxHeight,
              'min-height': callToActionMinHeight,
              'background-image': 'linear-gradient(var(--bs-primary), var(--bs-light))',
              'font-family': 'Exo, sans-serif',
              'text-shadow': '0 0.5rem 1rem rgba(0, 0, 0, 0.15) ',
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
              }}
            >
              <Image src={lightLogo} style={{ height: '400px' }} className="align-bottom" />
            </div>
            <div
              className="m-3"
              style={{
                position: 'absolute',
                left: 0,
                top: 50,
              }}
            >
              <h3 className=" text-uppercase fw-bold text-light">
                Al je dashboards bekijken en
                <br />
                delen vanaf 1 plek
              </h3>
              <div className="mt-3">
                <h1 className="text-uppercase fw-bold text-light">
                  Dat is HELD-R
                </h1>
                <Button variant="light" size="lg" as={NavLink} className="shadow" to={routes.auth.signup}>Start met delen</Button>
                <Button variant="outline-light" as={NavLink} className="ms-3 shadow" size="lg" to={routes.auth.login}>Log in</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-5 mx-3 row-cols-1 row-cols-lg-3 gutter-4">
        <Col>
          <FactCard
            title="POWER BI"
            text="Momenteel ondersteunt HELD-R Power BI. Andere BI tools volgen snel!"
            icon={<BarChartFill size={iconsSize} />}
          />
        </Col>
        <Col>
          <FactCard
            title="CROSS TENANT"
            text="Deel dashboards met iedereen, zonder dat ze een Power BI licentie nodig hebben. Binnen of buiten je organisatie."
            icon={<Shuffle size={iconsSize} />}
          />
        </Col>
        <Col>
          <FactCard
            title="ACCESS CONTROL"
            text="Beheer gemakkelijk toegang tot dashboards aan gebruikers."
            icon={<PersonFillLock size={iconsSize} />}
          />
        </Col>
      </Row>
      <Row className="my-5 mx-3">
        <Col sm={12} md={6}>
          <h3>Dashboards Bekijken</h3>
          <p>
            <NavLink to={routes.auth.signup}>Maak een HELD-R account aan</NavLink>
            &nbsp;en bekijk alle dashboards die met
            jou zijn gedeeld. Je krijgt gelijk toegang tot ons demo project.
            Staat een dashboard er niet tussen? Neem contact op met de beheerder
            van het dashboard om toegang te krijgen.
          </p>
        </Col>
        <Col md={6}>
          <h3>Dashboards Delen</h3>
          <p>
            Voor het delen van dashboards heb je een bedrijfs account nodig.&nbsp;
            <NavLink to={routes.auth.signup}>Maak een HELD-R account aan</NavLink>
            &nbsp;en neem vervolgens contact met ons op. Wij zorgen ervoor dat je bedrijf wordt
            toegevoegd aan HELD-R en dat je admin rechten krijgt. Je kan dan dashboards toevoegen
            en HELD-R gebruikers toegang geven.
          </p>
        </Col>
      </Row>
      <Row className="my-5 mx-3">
        <Col md={6}>
          <h3>
            Is HELD-R iets voor jou?
          </h3>
          <p>
            Het delen van een Power BI dashboard kan heel wat voeten in aarde hebben.
            Gasten moeten worden toegevoegd aan de tenant en de Power BI omgeving en moeten
            een Power BI licentie hebben. Vaak resulteert het
            in het delen van PDF bestanden, waarmee de interactiviteit van het dashboard verloren
            gaat.
            <br />
            <br />
            Als je als gebruiker bij meerdere organisatie werkt, dan moet je voor iedere
            organisatie een aparte Power BI licentie hebben. Daarnaast moet je steeds schakelen
            tussen omgevingen. Dat kan een stuk eenvoudiger!
            <br />
            <br />
            Met HELD-R kan je Power BI dashboards delen met iedereen, zonder dat je daar
            extra licenties voor nodig hebt.
            Heb je Power BI Embedded of Premium Capacity? Dan kan je meteen van start!
          </p>
        </Col>
        <Col md={6}>
          <Image src={superUser} fluid width="100%" />
        </Col>
      </Row>
    </Container>
  );
}
