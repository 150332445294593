import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import {
  Outlet, Navigate,
} from 'react-router-dom';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import SideBarContentProvider from './SideBarContentProvider';

import routes from '../routes';
import SideBar from './SideBar';

export default function AppLayout() {
  const { token } = useContext(AuthenticationContext);

  if (!token) {
    return <Navigate to={routes.auth.login} />;
  }

  return (
    <SideBarContentProvider>
      <div className="d-flex" style={{ height: '100vh' }}>
        <SideBar />
        <Col className="d-flex">
          <Outlet />
        </Col>
      </div>
    </SideBarContentProvider>
  );
}
