import React from 'react';

export default function NoPage() {
  return (
    <div className="container d-flex align-items-center justify-content-center" style={{ height: '80%' }}>
      <h1>
        404 - Page Not Found
      </h1>
    </div>
  );
}
