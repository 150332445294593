import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';

import { AuthenticationContext } from './AuthenticationProvider';
import loginUser from '../../services/authentication/loginUser';
import { AlertContext } from '../AlertProvider';

import routes from '../routes';

export default function Login() {
  const { setToken } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser(username, password);
    if (response.status === 200) {
      const token = await response.json();
      setToken(token);
      navigate(routes.app.index);
      return;
    }
    if (response.status === 401) {
      setValidated(true);
      const body = await response.json();
      setErrorMsg(body.detail);
      return;
    }
    addAlert('danger', 'Something went wrong.');
  };
  return (
    <Form onSubmit={handleSubmit} className="">
      <h1 className="h3 mb-3 font-weight-normal">Please sign-in</h1>
      <div className="form-floating">
        <Form.Control
          id="emailInput"
          className="top"
          type="email"
          onChange={(e) => setUserName(e.target.value)}
          isInvalid={validated}
        />
        <Form.Label htmlFor="emailInput">
          Email
        </Form.Label>
      </div>
      <div className="form-floating">
        <Form.Control
          id="passwordInput"
          className="bottom"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={validated}
        />
        <Form.Label htmlFor="passwordInput">
          Password
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          {errorMsg}
        </Form.Control.Feedback>
      </div>
      <div className="d-grid mt-2">
        <Button size="lg" type="submit">Submit</Button>
      </div>
      <p>
        <Link to={routes.auth.signup}>
          Sign up for an account.
        </Link>
        <br />
        <Link to={routes.auth.resetPassword}>
          Forgot password?
        </Link>
      </p>
    </Form>
  );
}
