import React, { useState, useContext } from 'react';
import {
  Nav, Collapse, Row, Col,
} from 'react-bootstrap';
import getProject from '../../../services/projects/getProject';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import ThemeItems from './ThemeItems';
import CollapseButtonLoad from '../../generic/CollapseButtonLoad';
import SideBarSpinner from './SideBarSpinner';

export default function ProjectItem(params) {
  const {
    project,
    setDashboard,
  } = params;

  const { authHeader } = useContext(AuthenticationContext);

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [themes, setThemes] = useState([]);

  async function fetchProject() {
    const response = await getProject(
      authHeader,
      project.company.uuid,
      project.uuid,
    );
    if (response.status === 200) {
      const body = await response.json();
      setThemes(body.themes);
      setLoaded(true);
    }
  }

  return (
    <>
      <Nav.Item onClick={() => setOpen((prevOpen) => !prevOpen)} style={{ width: '100%' }}>
        <Nav.Link className="w-100">
          <Row>
            <div style={{ width: 20 }}>
              <CollapseButtonLoad
                open={open}
                setOpen={setOpen}
                setLoaded={setLoaded}
              />
            </div>
            <Col>
              {project.name}
            </Col>
          </Row>
        </Nav.Link>
      </Nav.Item>
      <Collapse in={open} onEnter={() => fetchProject()}>
        <Row className="ms-2">
          {loaded ? (
            <ThemeItems
              themes={themes}
              projectUuid={project.uuid}
              companyUuid={project.company.uuid}
              setDashboard={setDashboard}
            />
          ) : <SideBarSpinner />}
        </Row>
      </Collapse>

    </>
  );
}
