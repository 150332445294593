import React, {
  useState, useContext, useCallback, useEffect,
} from 'react';
import {
  Button, Col, ListGroup, Row,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import EditProject from './ProjectEdit';
import postProject from '../../../services/projects/postProject';
import Project from './Project';
import getProjects from '../../../services/projects/getProjects';
import Loader from '../../generic/Loader';
import { AuthorizationContext } from '../../auth/AuthorizationProvider';
import { AlertContext } from '../../AlertProvider';

export default function Projects(props) {
  const {
    companyUuid,
  } = props;
  const { authHeader } = useContext(AuthenticationContext);
  const { authorization } = useContext(AuthorizationContext);
  const { addAlert } = useContext(AlertContext);

  const [selectedProjectId, setSelectedProjectId] = useState();
  const [showProjectEdit, setShowProjectEdit] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProjects() {
      const response = await getProjects(authHeader, companyUuid);
      if (response.status === 200) {
        const body = await response.json();
        setProjects(body);
      }
      setLoading(false);
    }
    fetchProjects();
  }, [authHeader, companyUuid]);

  const handleCreateProject = useCallback(async () => {
    const project = { name: 'New Project', uuid: uuidv4() };
    const response = await postProject(authHeader, companyUuid, project);
    if (response.status !== 200) {
      addAlert('danger', 'Failed to create project');
      return;
    }
    setProjects([...projects, project]);
    setSelectedProjectId(project.uuid);
    setShowProjectEdit(true);
  }, [
    projects,
  ]);

  const handleEditProject = useCallback((projectUuid) => {
    setSelectedProjectId(projectUuid);
    setShowProjectEdit(true);
  }, [
    setSelectedProjectId,
    setShowProjectEdit,
  ]);

  const handleProjectsChange = useCallback((uuid, project) => {
    const newProjects = [...projects];
    const index = newProjects.findIndex((p) => p.uuid === uuid);
    newProjects[index] = project;
    setProjects(newProjects);
  }, [
    projects,
    setProjects,
  ]);

  const handleProjectDelete = useCallback((uuid) => {
    const newProjects = projects.filter((project) => project.uuid !== uuid);
    setProjects(newProjects);
  }, [
    projects,
    setProjects,
  ]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Row className="mb-3">
        <Col>
          <ListGroup>
            {projects.map((project) => (
              <Project
                key={project.uuid}
                project={project}
                companyUuid={companyUuid}
                handleEditProject={handleEditProject}
              />
            ))}
          </ListGroup>
          {showProjectEdit ? (
            <EditProject
              selectedProjectUuid={selectedProjectId}
              handleProjectsChange={handleProjectsChange}
              handleProjectDelete={handleProjectDelete}
              setShowProjectEdit={setShowProjectEdit}
              companyUuid={companyUuid}
            />
          ) : undefined}
        </Col>
      </Row>
      <Row>
        <Col className="text-end">
          <Button
            onClick={handleCreateProject}
            disabled={!authorization.project.create}
          >
            Add Project
          </Button>
        </Col>
      </Row>
    </>
  );
}
