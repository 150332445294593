import React from 'react';
import {
  Outlet,
} from 'react-router-dom';
import '../../css/authContainer.css';
import NavBar from './NavBar';
import Footer from './Footer';

export default function HomeLayout() {
  return (
    <div>
      <header>
        <NavBar inApp />
      </header>
      <main className="remainder-height py-5">
        <Outlet />
      </main>
      <footer className="p-5 bg-white fw-light shadow mt-auto">
        <Footer />
      </footer>
    </div>
  );
}
